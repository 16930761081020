import React from "react";
import "./TopResume.css";

const TopResume = (props) => {
  return (
    <div className="ResumeContainer">
      <button
        style={{
          height: "50px",
          borderRadius: "5%",
          margin: "10px",
          fontWeight: "bold",
          fontSize: "20px",
          backgroundColor: "black",
        }}
      >
        <a
          style={{ color: "white" }}
          target="_blank"
          href="./JBuffaloe Resume.pdf"
          download
        >
          Download Here
        </a>
      </button>
      <div className="WordSection1">
        <p className="MsoNormal" style={{ lineHeight: "115%", border: "none" }}>
          <span style={{ fontFamily: "Arial", color: "black" }}>&nbsp;</span>
        </p>

        <table
          className="a6"
          border="1"
          cellspacing="0"
          cellpadding="0"
          width="720"
          style={{ borderCollapse: "collapse", border: "none" }}
        >
          <tr>
            <td
              style={{
                valign: "top",
                width: "301.5pt",
                border: "none",
                padding: "0in 5.75pt 0in 5.75pt",
              }}
            >
              <p className="MsoNormal">
                <b>
                  <span style={{ fontSize: "18pt", fontFamily: "Constantia" }}>
                    Jordan Buffaloe
                  </span>
                  {/* <br />
                  <span style={{ fontSize: "12pt", fontFamily: "Constantia" }}>
                    Product Manager
                  </span>
                  <br />
                  <span style={{ fontSize: "12pt", fontFamily: "Constantia" }}>
                    CSM, PMP, MBA, SAFe 5
                  </span> */}
                </b>
              </p>
            </td>
            <td
              width="318"
              valign="top"
              style={{
                width: "238.5pt",
                border: "none",
                padding: "0in 5.75pt 0in 5.75pt",
              }}
            >
              <p
                className="MsoNormal"
                align="right"
                style={{ textAlign: "right" }}
              >
                <span style={{ fontSize: "10pt", fontFamily: "Constantia" }}>
                  Venice, CA, 90291 | (904) 525-9229
                </span>
              </p>
              <p
                className="MsoNormal"
                align="right"
                style={{ textAlign: "right" }}
              >
                <span style={{ fontSize: "10pt", fontFamily: "Constantia" }}>
                  <a
                    href="mailto:jordanbuffaloe@gmail.com?Subject=Website Introduction"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    jordanbuffaloe@gmail.com
                  </a>
                </span>
              </p>
              <p
                className="MsoNormal"
                align="right"
                style={{ textAlign: "right" }}
              >
                <span style={{ fontSize: "10pt", fontFamily: "Constantia" }}>
                  <a
                    href="https://github.com/jordanbuffaloe"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    github: jordanbuffaloe
                  </a>
                </span>
              </p>
              <p
                className="MsoNormal"
                align="right"
                style={{ textAlign: "right" }}
              >
                <span style={{ fontSize: "10pt", fontFamily: "Constantia" }}>
                  <a
                    href="https://www.linkedin.com/in/jordanbuffaloe/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    linkedin.com/in/jordanbuffaloe
                  </a>
                </span>
              </p>
              <p
                className="MsoNormal"
                align="right"
                style={{ textAlign: "right" }}
              >
                <b>
                  <span
                    style={{
                      fontSize: "10pt",
                      fontFamily: "Constantia",
                      color: "#e2391b",
                    }}
                  >
                    www.jordanbuffaloe.com
                  </span>
                </b>
              </p>
            </td>
          </tr>
        </table>

        <div
          style={{
            border: "none",
            borderBottom: "solid #a68f0d 1.5pt",
            padding: "0in 0in 1pt 0in",
          }}
        >
          <p className="MsoNormal" style={{ border: "none", padding: "0in" }}>
            <b>
              <span
                style={{
                  fontSize: "10pt",
                  fontFamily: "Constantia",
                  color: "red",
                }}
              >
                &nbsp;
              </span>
            </b>
          </p>
        </div>

        <table
          className="a7"
          border="1"
          cellspacing="0"
          cellpadding="0"
          width="720"
          style={{ borderCollapse: "collapse", border: "none" }}
        >
          <tr>
            <td
              width="720"
              valign="top"
              style={{
                width: "7.5in",
                border: "none",
                padding: "0in 5.75pt 0in 5.75pt",
              }}
            >
              <p className="MsoNormal" style={{ marginTop: "12pt" }}>
                <b>
                  <span
                    style={{
                      fontWeight: "bold",
                      fontSize: "10pt",
                      fontFamily: "Constantia",
                    }}
                  >
                    QUALIFICATIONS SUMMARY
                  </span>
                </b>
              </p>

              <p
                className="MsoNormal Resume__Summary"
                style={{
                  marginTop: "6pt",
                  marginRight: "0in",
                  marginBottom: "6pt",
                  marginLeft: "0in",
                  textAlign: "justify",
                }}
              >
                <span style={{ fontSize: "10pt", fontFamily: "Constantia" }}>
                  Technical product and project leader adept at gathering and
                  translating business requirements into user stories,
                  synchronizing technology and operations team efforts, and
                  leveraging advanced technical skills to create automation
                  solutions that redefine what is possible. Steers high
                  performing business and engineering teams through effective
                  communication, mentorship, and skill building training that
                  drives employee satisfaction and enables high velocity in an
                  Agile environment. Shapes project success with a strong work
                  ethic, business acumen, and sustainable processes that
                  streamline both product feature development and operations and
                  to deliver measurable results.
                </span>
              </p>
              <table
                className="a8"
                border="1"
                cellspacing="0"
                cellpadding="0"
                width="731"
                style={{ borderCollapse: "collapse", border: "none" }}
              >
                <tr style={{ height: "63.45pt" }}>
                  <td
                    width="264"
                    valign="top"
                    style={{
                      width: "2.75in",
                      border: "none",
                      padding: "0in 5.75pt 0in 5.75pt",
                      height: "63.45pt",
                    }}
                  >
                    <p
                      className="MsoNormal"
                      style={{ marginLeft: "-1.5pt", textIndent: "0in" }}
                    >
                      <span
                        style={{
                          fontSize: "10pt",
                          fontFamily: "Noto Sans Symbols",
                        }}
                      >
                        &#9679;
                        <span style={{ font: "7pt Times New Roman" }}>
                          &nbsp;&nbsp;
                        </span>
                      </span>
                      <span
                        style={{ fontSize: "10pt", fontFamily: "Constantia" }}
                      >
                        Product Management
                      </span>
                    </p>
                    <p
                      className="MsoNormal"
                      style={{ marginLeft: "-1.5pt", textIndent: "0in" }}
                    >
                      <span
                        style={{
                          fontSize: "10pt",
                          fontFamily: "Noto Sans Symbols",
                        }}
                      >
                        &#9679;
                        <span style={{ font: "7pt Times New Roman" }}>
                          &nbsp;&nbsp;
                        </span>
                      </span>
                      <span
                        style={{ fontSize: "10pt", fontFamily: "Constantia" }}
                      >
                        Change Management
                      </span>
                    </p>
                    <p
                      className="MsoNormal"
                      style={{ marginLeft: "-1.5pt", textIndent: "0in" }}
                    >
                      <span
                        style={{
                          fontSize: "10pt",
                          fontFamily: "Noto Sans Symbols",
                        }}
                      >
                        &#9679;
                        <span style={{ font: "7pt Times New Roman" }}>
                          &nbsp;&nbsp;
                        </span>
                      </span>
                      <span
                        style={{ fontSize: "10pt", fontFamily: "Constantia" }}
                      >
                        Client Engagement
                      </span>
                    </p>
                    <p
                      className="MsoNormal"
                      style={{ marginLeft: "-1.5pt", textIndent: "0in" }}
                    >
                      <span
                        style={{
                          fontSize: "10pt",
                          fontFamily: "Noto Sans Symbols",
                        }}
                      >
                        &#9679;
                        <span style={{ font: "7pt Times New Roman" }}>
                          &nbsp;&nbsp;
                        </span>
                      </span>
                      <span
                        style={{ fontSize: "10pt", fontFamily: "Constantia" }}
                      >
                        Relationship Management
                      </span>
                    </p>
                    <p
                      className="MsoNormal"
                      style={{ marginLeft: "-1.5pt", textIndent: "0in" }}
                    >
                      <span
                        style={{
                          fontSize: "10pt",
                          fontFamily: "Noto Sans Symbols",
                        }}
                      >
                        &#9679;
                        <span style={{ font: "7pt Times New Roman" }}>
                          &nbsp;&nbsp;
                        </span>
                      </span>
                      <span
                        style={{ fontSize: "10pt", fontFamily: "Constantia" }}
                      >
                        Software Development Life Cycle
                      </span>
                    </p>
                  </td>
                  <td
                    width="240"
                    valign="top"
                    style={{
                      width: "2.5in",
                      border: "none",
                      padding: "0in 5.75pt 0in 5.75pt",
                      height: "63.45pt",
                    }}
                  >
                    <p
                      className="MsoNormal"
                      style={{
                        marginLeft: "12.75pt",
                        textIndent: "-13.5pt",
                        border: "none",
                      }}
                    >
                      <span
                        style={{
                          fontSize: "10pt",
                          fontFamily: "Noto Sans Symbols",
                          color: "black",
                        }}
                      >
                        &#9679;
                        <span style={{ font: "7pt Times New Roman" }}>
                          &nbsp;&nbsp;&nbsp;
                        </span>
                      </span>
                      <span
                        style={{
                          fontSize: "10pt",
                          fontFamily: "Constantia",
                          color: "black",
                        }}
                      >
                        Agile Product Management
                      </span>
                    </p>
                    <p
                      className="MsoNormal"
                      style={{
                        marginLeft: "12.75pt",
                        textIndent: "-13.5pt",
                        border: "none",
                      }}
                    >
                      <span
                        style={{
                          fontSize: "10pt",
                          fontFamily: "Noto Sans Symbols",
                          color: "black",
                        }}
                      >
                        &#9679;
                        <span style={{ font: "7pt Times New Roman" }}>
                          &nbsp;&nbsp;&nbsp;
                        </span>
                      </span>
                      <span
                        style={{
                          fontSize: "10pt",
                          fontFamily: "Constantia",
                          color: "black",
                        }}
                      >
                        Technical Operations Management
                      </span>
                    </p>
                    <p
                      className="MsoNormal"
                      style={{
                        marginLeft: "12.75pt",
                        textIndent: "-13.5pt",
                        border: "none",
                      }}
                    >
                      <span
                        style={{
                          fontSize: "10pt",
                          fontFamily: "Noto Sans Symbols",
                          color: "black",
                        }}
                      >
                        &#9679;
                        <span style={{ font: "7pt Times New Roman" }}>
                          &nbsp;&nbsp;&nbsp;
                        </span>
                      </span>
                      <span
                        style={{
                          fontSize: "10pt",
                          fontFamily: "Constantia",
                          color: "black",
                        }}
                      >
                        Team Leadership &amp; Management
                      </span>
                    </p>
                    <p
                      className="MsoNormal"
                      style={{
                        marginLeft: "12.75pt",
                        textIndent: "-13.5pt",
                        border: "none",
                      }}
                    >
                      <span
                        style={{
                          fontSize: "10pt",
                          fontFamily: "Noto Sans Symbols",
                          color: "black",
                        }}
                      >
                        &#9679;
                        <span style={{ font: "7pt Times New Roman" }}>
                          &nbsp;&nbsp;&nbsp;
                        </span>
                      </span>
                      <span
                        style={{
                          fontSize: "10pt",
                          fontFamily: "Constantia",
                          color: "black",
                        }}
                      >
                        Requirements Gathering
                      </span>
                    </p>
                    <p
                      className="MsoNormal"
                      style={{
                        marginLeft: "12.75pt",
                        textIndent: "-13.5pt",
                        border: "none",
                      }}
                    >
                      <span
                        style={{
                          fontSize: "10pt",
                          fontFamily: "Noto Sans Symbols",
                          color: "black",
                        }}
                      >
                        &#9679;
                        <span style={{ font: "7pt Times New Roman" }}>
                          &nbsp;&nbsp;&nbsp;
                        </span>
                      </span>
                      <span
                        style={{
                          fontSize: "10pt",
                          fontFamily: "Constantia",
                          color: "black",
                        }}
                      >
                        Automation Solution Development
                      </span>
                    </p>
                  </td>
                  <td
                    width="227"
                    valign="top"
                    style={{
                      width: "170.05pt",
                      border: "none",
                      padding: "0in 5.75pt 0in 5.75pt",
                      height: "63.45pt",
                    }}
                  >
                    <p
                      className="MsoNormal"
                      style={{
                        marginLeft: "17.25pt",
                        textIndent: "-13.5pt",
                        border: "none",
                      }}
                    >
                      <span
                        style={{
                          fontSize: "10pt",
                          fontFamily: "Noto Sans Symbols",
                          color: "black",
                        }}
                      >
                        &#9679;
                        <span style={{ font: "7pt Times New Roman" }}>
                          &nbsp;&nbsp;&nbsp;&nbsp;
                        </span>
                      </span>
                      <span
                        style={{
                          fontSize: "10pt",
                          fontFamily: "Constantia",
                          color: "black",
                        }}
                      >
                        Scrum Framework
                      </span>
                    </p>
                    <p
                      className="MsoNormal"
                      style={{
                        marginLeft: "17.25pt",
                        textIndent: "-13.5pt",
                        border: "none",
                      }}
                    >
                      <span
                        style={{
                          fontSize: "10pt",
                          fontFamily: "Noto Sans Symbols",
                          color: "black",
                        }}
                      >
                        &#9679;
                        <span style={{ font: "7pt Times New Roman" }}>
                          &nbsp;&nbsp;&nbsp;&nbsp;
                        </span>
                      </span>
                      <span
                        style={{
                          fontSize: "10pt",
                          fontFamily: "Constantia",
                          color: "black",
                        }}
                      >
                        Agile Methodologies
                      </span>
                    </p>
                    <p
                      className="MsoNormal"
                      style={{
                        marginLeft: "17.25pt",
                        textIndent: "-13.5pt",
                        border: "none",
                      }}
                    >
                      <span
                        style={{
                          fontSize: "10pt",
                          fontFamily: "Noto Sans Symbols",
                          color: "black",
                        }}
                      >
                        &#9679;
                        <span style={{ font: "7pt Times New Roman" }}>
                          &nbsp;&nbsp;&nbsp;&nbsp;
                        </span>
                      </span>
                      <span
                        style={{
                          fontSize: "10pt",
                          fontFamily: "Constantia",
                          color: "black",
                        }}
                      >
                        Business Analysis
                      </span>
                    </p>
                    <p
                      className="MsoNormal"
                      style={{
                        marginLeft: "17.25pt",
                        textIndent: "-13.5pt",
                        border: "none",
                      }}
                    >
                      <span
                        style={{
                          fontSize: "10pt",
                          fontFamily: "Noto Sans Symbols",
                          color: "black",
                        }}
                      >
                        &#9679;
                        <span style={{ font: "7pt Times New Roman" }}>
                          &nbsp;&nbsp;&nbsp;&nbsp;
                        </span>
                      </span>
                      <span
                        style={{
                          fontSize: "10pt",
                          fontFamily: "Constantia",
                          color: "black",
                        }}
                      >
                        Information Technology
                      </span>
                    </p>
                    <p
                      className="MsoNormal"
                      style={{
                        marginLeft: "17.25pt",
                        textIndent: "-13.5pt",
                        border: "none",
                      }}
                    >
                      <span
                        style={{
                          fontSize: "10pt",
                          fontFamily: "Noto Sans Symbols",
                          color: "black",
                        }}
                      >
                        &#9679;
                        <span style={{ font: "7pt Times New Roman" }}>
                          &nbsp;&nbsp;&nbsp;&nbsp;
                        </span>
                      </span>
                      <span
                        style={{
                          fontSize: "10pt",
                          fontFamily: "Constantia",
                          color: "black",
                        }}
                      >
                        Risk Assessment &amp; Reporting
                      </span>
                    </p>
                  </td>
                </tr>
              </table>
              <p className="MsoNormal"></p>
            </td>
          </tr>
        </table>

        <div
          style={{
            border: "none",
            borderBottom: "solid #a68f0d 1.5pt",
            padding: "0in 0in 1pt 0in",
          }}
        >
          <p className="MsoNormal" style={{ border: "none", padding: "0in" }}>
            <b>
              <span
                style={{
                  fontSize: "10pt",
                  fontFamily: "Constantia",
                  color: "red",
                }}
              >
                &nbsp;
              </span>
            </b>
          </p>
        </div>

        <table
          className="a9"
          border="1"
          cellspacing="0"
          cellpadding="0"
          width="720"
          style={{ borderCollapse: "collapse", border: "none" }}
        >
          <tr>
            <td
              width="720"
              valign="top"
              style={{
                width: "7.5in",
                border: "none",
                padding: "0in 5.75pt 0in 5.75pt",
              }}
            >
              <p className="MsoNormal" style={{ marginTop: "12pt" }}>
                <b>
                  <span
                    style={{
                      fontWeight: "bold",
                      fontSize: "10pt",
                      fontFamily: "Constantia",
                    }}
                  >
                    PROFESSIONAL EXPERIENCE
                  </span>
                </b>
              </p>
              <p className="MsoNormal" style={{ marginTop: "6pt" }}>
                <span
                  style={{
                    fontWeight: "bold",
                    fontSize: "10pt",
                    fontFamily: "Constantia",
                  }}
                >
                  BOX, INC. 2021 to Present
                </span>
              </p>
              <br />
              <p className="MsoNormal">
                <b>
                  <u>
                    <span
                      style={{
                        fontWeight: "bold",
                        fontSize: "10pt",
                        fontFamily: "Constantia",
                      }}
                    >
                      Senior Product Manager
                    </span>
                  </u>
                </b>
                <span style={{ fontSize: "10pt", fontFamily: "Constantia" }}>
                  , Sep 2021 to Present
                </span>
              </p>
              <p
                className="MsoNormal"
                style={{ marginTop: "3pt", textAlign: "justify" }}
              >
                <span style={{ fontSize: "10pt", fontFamily: "Constantia" }}>
                Responsible for identifying, prioritizing and communicating work for four development teams that specialize in Productivity Engineering for 70+ Box Engineering Teams in a Cloud-based environment. 
                </span>
              </p>
              <p
                className="MsoNormal"
                style={{
                  marginTop: "3pt",
                  marginRight: "0in",
                  marginBottom: "0in",
                  marginLeft: "0.25in",
                  textIndent: "-13.5pt",
                }}
              >
                <span
                  style={{
                    fontSize: "10pt",
                    fontFamily: "Noto Sans Symbols",
                  }}
                >
                  &#9679;
                  <span style={{ font: "7pt Times New Roman" }}>
                    &nbsp;&nbsp;&nbsp;
                  </span>
                </span>
                <b>
                  <span
                    style={{
                      fontWeight: "bold",
                      fontSize: "10pt",
                      fontFamily: "Constantia",
                    }}
                  >
                    Aggregating Insights and Workflow Automation:{" "}
                  </span>
                </b>
                <span style={{ fontSize: "10pt", fontFamily: "Constantia" }}>
                Building out an implementation of Spotfy’s Backstage Developer Portal to drive Developer Productivity.
                </span>
              </p>
              <p
                className="MsoNormal"
                style={{
                  marginTop: "3pt",
                  marginRight: "0in",
                  marginBottom: "0in",
                  marginLeft: "0.25in",
                  textIndent: "-13.5pt",
                }}
              >
                <span
                  style={{
                    fontSize: "10pt",
                    fontFamily: "Noto Sans Symbols",
                  }}
                >
                  &#9679;
                  <span style={{ font: "7pt Times New Roman" }}>
                    &nbsp;&nbsp;&nbsp;
                  </span>
                </span>
                <b>
                  <span
                    style={{
                      fontWeight: "bold",
                      fontSize: "10pt",
                      fontFamily: "Constantia",
                    }}
                  >
                    Behavior Driven Development:{" "}
                  </span>
                </b>
                <span style={{ fontSize: "10pt", fontFamily: "Constantia" }}>
                Implementing a common end to end Javascript testing framework for Front-End Teams to automate tests using Behavior Driven Development.
                </span>
              </p>
              <p
                className="MsoNormal"
                style={{
                  marginTop: "3pt",
                  marginRight: "0in",
                  marginBottom: "0in",
                  marginLeft: "0.25in",
                  textIndent: "-13.5pt",
                }}
              >
                <span
                  style={{
                    fontSize: "10pt",
                    fontFamily: "Noto Sans Symbols",
                  }}
                >
                  &#9679;
                  <span style={{ font: "7pt Times New Roman" }}>
                    &nbsp;&nbsp;&nbsp;
                  </span>
                </span>
                <b>
                  <span
                    style={{
                      fontWeight: "bold",
                      fontSize: "10pt",
                      fontFamily: "Constantia",
                    }}
                  >
                    Continuous Integration / Continuous Delivery practices:{" "}
                  </span>
                </b>
                <span style={{ fontSize: "10pt", fontFamily: "Constantia" }}>
                Building a standardized Deployment process that has built in security, compliance and efficiency practices.
                </span>
              </p>
              <p
                className="MsoNormal"
                style={{
                  marginTop: "3pt",
                  marginRight: "0in",
                  marginBottom: "0in",
                  marginLeft: "0.25in",
                  textIndent: "-13.5pt",
                }}
              >
                <span
                  style={{
                    fontSize: "10pt",
                    fontFamily: "Noto Sans Symbols",
                  }}
                >
                  &#9679;
                  <span style={{ font: "7pt Times New Roman" }}>
                    &nbsp;&nbsp;&nbsp;
                  </span>
                </span>
                <b>
                  <span
                    style={{
                      fontWeight: "bold",
                      fontSize: "10pt",
                      fontFamily: "Constantia",
                    }}
                  >
                    Migration to Cloud:{" "}
                  </span>
                </b>
                <span style={{ fontSize: "10pt", fontFamily: "Constantia" }}>
                Empowering teams to move from an on-prem to a Cloud environment.
                </span>
              </p>
              <p
                className="MsoNormal"
                style={{
                  marginTop: "3pt",
                  marginRight: "0in",
                  marginBottom: "0in",
                  marginLeft: "0.25in",
                  textIndent: "-13.5pt",
                }}
              >
                <span
                  style={{
                    fontSize: "10pt",
                    fontFamily: "Noto Sans Symbols",
                  }}
                >
                  &#9679;
                  <span style={{ font: "7pt Times New Roman" }}>
                    &nbsp;&nbsp;&nbsp;
                  </span>
                </span>
                <b>
                  <span
                    style={{
                      fontWeight: "bold",
                      fontSize: "10pt",
                      fontFamily: "Constantia",
                    }}
                  >
                    Data Governance:{" "}
                  </span>
                </b>
                <span style={{ fontSize: "10pt", fontFamily: "Constantia" }}>
                Ensuring that our service metadata stays up to date and accurate to maintain compliance.
                </span>
              </p>

              <br />
              <p className="MsoNormal" style={{ marginTop: "6pt" }}>
                <span
                  style={{
                    fontWeight: "bold",
                    fontSize: "10pt",
                    fontFamily: "Constantia",
                  }}
                >
                  NAMELY, INC. 2015 to 2021
                </span>
              </p>
              <br />
              <p className="MsoNormal">
                <b>
                  <u>
                    <span
                      style={{
                        fontWeight: "bold",
                        fontSize: "10pt",
                        fontFamily: "Constantia",
                      }}
                    >
                      Product Manager
                    </span>
                  </u>
                </b>
                <span style={{ fontSize: "10pt", fontFamily: "Constantia" }}>
                  , Apr 2020 to Sep 2021
                </span>
              </p>
              <p
                className="MsoNormal"
                style={{ marginTop: "3pt", textAlign: "justify" }}
              >
                <span style={{ fontSize: "10pt", fontFamily: "Constantia" }}>
                  Responsible for identifying and prioritizing use cases for a
                  dedicated development team that automates and makes internal
                  operational work more efficient.
                </span>
              </p>
              <p
                className="MsoNormal"
                style={{
                  marginTop: "3pt",
                  marginRight: "0in",
                  marginBottom: "0in",
                  marginLeft: "0.25in",
                  textIndent: "-13.5pt",
                }}
              >
                <span
                  style={{
                    fontSize: "10pt",
                    fontFamily: "Noto Sans Symbols",
                  }}
                >
                  &#9679;
                  <span style={{ font: "7pt Times New Roman" }}>
                    &nbsp;&nbsp;&nbsp;
                  </span>
                </span>
                <b>
                  <span
                    style={{
                      fontWeight: "bold",
                      fontSize: "10pt",
                      fontFamily: "Constantia",
                    }}
                  >
                    Technical Liaison:{" "}
                  </span>
                </b>
                <span style={{ fontSize: "10pt", fontFamily: "Constantia" }}>
                  Identifies automation opportunities across operational
                  departments
                </span>
              </p>
              <p
                className="MsoNormal"
                style={{
                  marginTop: "3pt",
                  marginRight: "0in",
                  marginBottom: "0in",
                  marginLeft: "0.25in",
                  textIndent: "-13.5pt",
                }}
              >
                <span
                  style={{
                    fontSize: "10pt",
                    fontFamily: "Noto Sans Symbols",
                  }}
                >
                  &#9679;
                  <span style={{ font: "7pt Times New Roman" }}>
                    &nbsp;&nbsp;&nbsp;
                  </span>
                </span>
                <b>
                  <span
                    style={{
                      fontWeight: "bold",
                      fontSize: "10pt",
                      fontFamily: "Constantia",
                    }}
                  >
                    Solutions Designer:{" "}
                  </span>
                </b>
                <span style={{ fontSize: "10pt", fontFamily: "Constantia" }}>
                  Develops internal tools, ETL and process to support operations
                </span>
              </p>
              <p
                className="MsoNormal"
                style={{
                  marginTop: "3pt",
                  marginRight: "0in",
                  marginBottom: "0in",
                  marginLeft: "0.25in",
                  textIndent: "-13.5pt",
                }}
              >
                <span
                  style={{
                    fontSize: "10pt",
                    fontFamily: "Noto Sans Symbols",
                  }}
                >
                  &#9679;
                  <span style={{ font: "7pt Times New Roman" }}>
                    &nbsp;&nbsp;&nbsp;
                  </span>
                </span>
                <b>
                  <span
                    style={{
                      fontWeight: "bold",
                      fontSize: "10pt",
                      fontFamily: "Constantia",
                    }}
                  >
                    Vendor Management:{" "}
                  </span>
                </b>
                <span style={{ fontSize: "10pt", fontFamily: "Constantia" }}>
                  Responsible for onboarding vendors providing services related
                  to operational efficiency gains.
                </span>
              </p>
              <p
                className="MsoNormal"
                style={{
                  marginTop: "3pt",
                  marginRight: "0in",
                  marginBottom: "0in",
                  marginLeft: "0.25in",
                  textIndent: "-13.5pt",
                }}
              >
                <span
                  style={{
                    fontSize: "10pt",
                    fontFamily: "Noto Sans Symbols",
                  }}
                >
                  &#9679;
                  <span style={{ font: "7pt Times New Roman" }}>
                    &nbsp;&nbsp;&nbsp;
                  </span>
                </span>
                <b>
                  <span
                    style={{
                      fontWeight: "bold",
                      fontSize: "10pt",
                      fontFamily: "Constantia",
                    }}
                  >
                    Javascript Developer:{" "}
                  </span>
                </b>
                <span style={{ fontSize: "10pt", fontFamily: "Constantia" }}>
                  Maintains Full-stack Javascript Application that automates
                  operational functions
                </span>
              </p>

              <br />
              <p className="MsoNormal">
                <b>
                  <u>
                    <span
                      style={{
                        fontWeight: "bold",
                        fontSize: "10pt",
                        fontFamily: "Constantia",
                      }}
                    >
                      Senior Manager, Data Services
                    </span>
                  </u>
                </b>
                <span style={{ fontSize: "10pt", fontFamily: "Constantia" }}>
                  , 2018 to Apr 2020
                </span>
              </p>
              <p
                className="MsoNormal"
                style={{ marginTop: "3pt", textAlign: "justify" }}
              >
                <span style={{ fontSize: "10pt", fontFamily: "Constantia" }}>
                  Provides senior-level technical leadership through the
                  effective management of the entire data services program, from
                  product and projects to technical operations and engineering,
                  for the professional services department of a human resources
                  (HR) Software-as-a-Service (SaaS) firm. Automates operations
                  by crafting user stories and driving project completion from
                  ideation to production. Produces custom client-facing SQL
                  reports based on business key performance indicators (KPIs).
                </span>
              </p>
              <p
                className="MsoNormal"
                style={{
                  marginTop: "3pt",
                  marginRight: "0in",
                  marginBottom: "0in",
                  marginLeft: "0.25in",
                  textIndent: "-13.5pt",
                }}
              >
                <span
                  style={{
                    fontSize: "10pt",
                    fontFamily: "Noto Sans Symbols",
                  }}
                >
                  &#9679;
                  <span style={{ font: "7pt Times New Roman" }}>
                    &nbsp;&nbsp;&nbsp;
                  </span>
                </span>
                <b>
                  <span
                    style={{
                      fontWeight: "bold",
                      fontSize: "10pt",
                      fontFamily: "Constantia",
                    }}
                  >
                    Team Leadership:{" "}
                  </span>
                </b>
                <span style={{ fontSize: "10pt", fontFamily: "Constantia" }}>
                  Integrated and trained a 30+ member offshore technical team.
                </span>
              </p>
              <p
                className="MsoNormal"
                style={{
                  marginTop: "3pt",
                  marginRight: "0in",
                  marginBottom: "0in",
                  marginLeft: "0.25in",
                  textIndent: "-13.5pt",
                }}
              >
                <span
                  style={{
                    fontSize: "10pt",
                    fontFamily: "Noto Sans Symbols",
                  }}
                >
                  &#9679;
                  <span style={{ font: "7pt Times New Roman" }}>
                    &nbsp;&nbsp;&nbsp;
                  </span>
                </span>
                <b>
                  <span
                    style={{
                      fontWeight: "bold",
                      fontSize: "10pt",
                      fontFamily: "Constantia",
                    }}
                  >
                    Team Management:{" "}
                  </span>
                </b>
                <span style={{ fontSize: "10pt", fontFamily: "Constantia" }}>
                  Supervised daily activities of a six-member team tasked with
                  technical and operational duties.
                </span>
              </p>
              <p
                className="MsoNormal"
                style={{
                  marginTop: "3pt",
                  marginRight: "0in",
                  marginBottom: "0in",
                  marginLeft: "0.25in",
                  textIndent: "-13.5pt",
                }}
              >
                <span
                  style={{
                    fontSize: "10pt",
                    fontFamily: "Noto Sans Symbols",
                  }}
                >
                  &#9679;
                  <span style={{ font: "7pt Times New Roman" }}>
                    &nbsp;&nbsp;&nbsp;
                  </span>
                </span>
                <b>
                  <span
                    style={{
                      fontWeight: "bold",
                      fontSize: "10pt",
                      fontFamily: "Constantia",
                    }}
                  >
                    Electronic Data Interchange (EDI):{" "}
                  </span>
                </b>
                <span style={{ fontSize: "10pt", fontFamily: "Constantia" }}>
                  Decreased new EDI operational work 50% in eight months.
                </span>
              </p>
              <p
                className="MsoNormal"
                style={{
                  marginTop: "3pt",
                  marginRight: "0in",
                  marginBottom: "0in",
                  marginLeft: "0.25in",
                  textIndent: "-13.5pt",
                }}
              >
                <span
                  style={{
                    fontSize: "10pt",
                    fontFamily: "Noto Sans Symbols",
                  }}
                >
                  &#9679;
                  <span style={{ font: "7pt Times New Roman" }}>
                    &nbsp;&nbsp;&nbsp;
                  </span>
                </span>
                <b>
                  <span
                    style={{
                      fontWeight: "bold",
                      fontSize: "10pt",
                      fontFamily: "Constantia",
                    }}
                  >
                    Data Validation Controls:{" "}
                  </span>
                </b>
                <span style={{ fontSize: "10pt", fontFamily: "Constantia" }}>
                  Initiated data validation controls that enhance operations
                  60%.
                </span>
              </p>
              <p
                className="MsoNormal"
                style={{
                  marginTop: "3pt",
                  marginRight: "0in",
                  marginBottom: "0in",
                  marginLeft: "0.25in",
                  textIndent: "-13.5pt",
                }}
              >
                <span
                  style={{
                    fontSize: "10pt",
                    fontFamily: "Noto Sans Symbols",
                  }}
                >
                  &#9679;
                  <span style={{ font: "7pt Times New Roman" }}>
                    &nbsp;&nbsp;&nbsp;
                  </span>
                </span>
                <b>
                  <span
                    style={{
                      fontWeight: "bold",
                      fontSize: "10pt",
                      fontFamily: "Constantia",
                    }}
                  >
                    Agile Methodology:{" "}
                  </span>
                </b>
                <span style={{ fontSize: "10pt", fontFamily: "Constantia" }}>
                  Pioneered use of<b> </b>Agile methodology across the
                  department by facilitating Scrum ceremonies.
                </span>
              </p>
              <p
                className="MsoNormal"
                style={{
                  marginTop: "3pt",
                  marginRight: "0in",
                  marginBottom: "0in",
                  marginLeft: "0.25in",
                  textIndent: "-13.5pt",
                }}
              >
                <span
                  style={{
                    fontSize: "10pt",
                    fontFamily: "Noto Sans Symbols",
                  }}
                >
                  &#9679;
                  <span style={{ font: "7pt Times New Roman" }}>
                    &nbsp;&nbsp;&nbsp;
                  </span>
                </span>
                <b>
                  <span
                    style={{
                      fontWeight: "bold",
                      fontSize: "10pt",
                      fontFamily: "Constantia",
                    }}
                  >
                    Data Services Dashboard:{" "}
                  </span>
                </b>
                <span style={{ fontSize: "10pt", fontFamily: "Constantia" }}>
                  Served as full-stack engineer by architecting a dashboard that
                  aggregated data to automate operations across four business
                  functions and executive reporting of key business metrics.
                </span>
              </p>
              <p
                className="MsoNormal"
                style={{
                  marginTop: "3pt",
                  marginRight: "0in",
                  marginBottom: "0in",
                  marginLeft: "1in",
                  marginBottom: "0.0001pt",
                  textIndent: "-0.25in",
                }}
              >
                <span style={{ fontSize: "10pt", fontFamily: "Courier New" }}>
                  <span style={{ font: "7pt Times New Roman" }}>
                    &nbsp;&nbsp;
                  </span>
                </span>
                <span style={{ fontSize: "10pt", fontFamily: "Constantia" }}>
                  Generated time savings of 5+ weekly hours across 237 end
                  users.
                </span>
              </p>
              <p
                className="MsoNormal"
                style={{
                  marginTop: "3pt",
                  marginRight: "0in",
                  marginBottom: "0in",
                  marginLeft: "1in",
                  marginBottom: "0.0001pt",
                  textIndent: "-0.25in",
                }}
              >
                <span style={{ fontSize: "10pt", fontFamily: "Courier New" }}>
                  <span style={{ font: "7pt Times New Roman" }}>
                    &nbsp;&nbsp;
                  </span>
                </span>
                <span style={{ fontSize: "10pt", fontFamily: "Constantia" }}>
                  Engineered API endpoints connecting Salesforce, JIRA and two
                  SQL databases.
                </span>
              </p>
              <p
                className="MsoNormal"
                style={{
                  marginTop: "3pt",
                  marginRight: "0in",
                  marginBottom: "0in",
                  marginLeft: "1in",
                  marginBottom: "0.0001pt",
                  textIndent: "-0.25in",
                }}
              >
                <span style={{ fontSize: "10pt", fontFamily: "Courier New" }}>
                  <span style={{ font: "7pt Times New Roman" }}>
                    &nbsp;&nbsp;
                  </span>
                </span>
                <span style={{ fontSize: "10pt", fontFamily: "Constantia" }}>
                  Transferred data exports onto client-facing spreadsheets by
                  leveraging exceljs library.
                </span>
              </p>
              <p
                className="MsoNormal"
                style={{
                  marginTop: "3pt",
                  marginRight: "0in",
                  marginBottom: "0in",
                  marginLeft: "0.25in",
                  textIndent: "-13.5pt",
                }}
              >
                <span
                  style={{
                    fontSize: "10pt",
                    fontFamily: "Noto Sans Symbols",
                  }}
                >
                  &#9679;
                  <span style={{ font: "7pt Times New Roman" }}>
                    &nbsp;&nbsp;&nbsp;
                  </span>
                </span>
                <b>
                  <span
                    style={{
                      fontWeight: "bold",
                      fontSize: "10pt",
                      fontFamily: "Constantia",
                    }}
                  >
                    Backlog Management:{" "}
                  </span>
                </b>
                <span style={{ fontSize: "10pt", fontFamily: "Constantia" }}>
                  Reduced work-in-progress 50% following departmental
                  reorganization.
                </span>
              </p>
              <p
                className="MsoNormal"
                style={{
                  marginTop: "3pt",
                  marginRight: "0in",
                  marginBottom: "0in",
                  marginLeft: "0.25in",
                  textIndent: "-13.5pt",
                }}
              >
                <span
                  style={{
                    fontSize: "10pt",
                    fontFamily: "Noto Sans Symbols",
                  }}
                >
                  &#9679;
                  <span style={{ font: "7pt Times New Roman" }}>
                    &nbsp;&nbsp;&nbsp;
                  </span>
                </span>
                <b>
                  <span
                    style={{
                      fontWeight: "bold",
                      fontSize: "10pt",
                      fontFamily: "Constantia",
                    }}
                  >
                    Process Implementation:{" "}
                  </span>
                </b>
                <span style={{ fontSize: "10pt", fontFamily: "Constantia" }}>
                  Decreased project turn-around time 60% by implementing new
                  processes.
                </span>
              </p>
              <p
                className="MsoNormal"
                style={{
                  marginTop: "3pt",
                  marginRight: "0in",
                  marginBottom: "0in",
                  marginLeft: "0.25in",
                  textIndent: "-13.5pt",
                }}
              >
                <span
                  style={{
                    fontSize: "10pt",
                    fontFamily: "Noto Sans Symbols",
                  }}
                >
                  &#9679;
                  <span style={{ font: "7pt Times New Roman" }}>
                    &nbsp;&nbsp;&nbsp;
                  </span>
                </span>
                <b>
                  <span
                    style={{
                      fontWeight: "bold",
                      fontSize: "10pt",
                      fontFamily: "Constantia",
                    }}
                  >
                    Automation:{" "}
                  </span>
                </b>
                <span style={{ fontSize: "10pt", fontFamily: "Constantia" }}>
                  Generated savings of 50 hours per week by designing and
                  building automation tools.
                </span>
              </p>
              <p
                className="MsoNormal"
                style={{
                  marginTop: "3pt",
                  marginRight: "0in",
                  marginBottom: "0in",
                  marginLeft: "0.25in",
                  textIndent: "-13.5pt",
                }}
              >
                <span
                  style={{
                    fontSize: "10pt",
                    fontFamily: "Noto Sans Symbols",
                  }}
                >
                  &#9679;
                  <span style={{ font: "7pt Times New Roman" }}>
                    &nbsp;&nbsp;&nbsp;
                  </span>
                </span>
                <b>
                  <span
                    style={{
                      fontWeight: "bold",
                      fontSize: "10pt",
                      fontFamily: "Constantia",
                    }}
                  >
                    Application Development:{" "}
                  </span>
                </b>
                <span style={{ fontSize: "10pt", fontFamily: "Constantia" }}>
                  Delivered a Super Mario-inspired CRUD app to empower business
                  users by serving as Product Owner and Front-end Engineer,
                  which reduced turnaround time from two days to 30 minutes.
                </span>
              </p>
              <p
                className="MsoNormal"
                style={{
                  marginTop: "3pt",
                  marginRight: "0in",
                  marginBottom: "0in",
                  marginLeft: "0.25in",
                  textIndent: "-13.5pt",
                }}
              >
                <span
                  style={{
                    fontSize: "10pt",
                    fontFamily: "Noto Sans Symbols",
                  }}
                >
                  &#9679;
                  <span style={{ font: "7pt Times New Roman" }}>
                    &nbsp;&nbsp;&nbsp;
                  </span>
                </span>
                <b>
                  <span
                    style={{
                      fontWeight: "bold",
                      fontSize: "10pt",
                      fontFamily: "Constantia",
                    }}
                  >
                    Report Generation:{" "}
                  </span>
                </b>
                <span style={{ fontSize: "10pt", fontFamily: "Constantia" }}>
                  Assembled client-facing scoping PDF reports that verified 30
                  data points, exposing errors and calculating resolution
                  recommendations.
                </span>
              </p>
              <p
                className="MsoNormal"
                style={{
                  marginTop: "3pt",
                  marginRight: "0in",
                  marginBottom: "0in",
                  marginLeft: "0.25in",
                  textIndent: "-13.5pt",
                }}
              >
                <span
                  style={{
                    fontSize: "10pt",
                    fontFamily: "Noto Sans Symbols",
                  }}
                >
                  &#9679;
                  <span style={{ font: "7pt Times New Roman" }}>
                    &nbsp;&nbsp;&nbsp;
                  </span>
                </span>
                <b>
                  <span
                    style={{
                      fontWeight: "bold",
                      fontSize: "10pt",
                      fontFamily: "Constantia",
                    }}
                  >
                    Business Leadership:{" "}
                  </span>
                </b>
                <span style={{ fontSize: "10pt", fontFamily: "Constantia" }}>
                  Ascended ranks through three promotions in five years to
                  Senior Data Services Manager.
                </span>
              </p>
              <br />
              <p className="MsoNormal" style={{ marginTop: "6pt" }}>
                <b>
                  <u>
                    <span
                      style={{
                        fontWeight: "bold",
                        fontSize: "10pt",
                        fontFamily: "Constantia",
                      }}
                    >
                      Manager, Technical Operations / Scrum Master
                    </span>
                  </u>
                </b>
                <span style={{ fontSize: "10pt", fontFamily: "Constantia" }}>
                  , 2016 to 2018
                </span>
              </p>
              <p
                className="MsoNormal"
                style={{ marginTop: "3pt", textAlign: "justify" }}
              >
                <span style={{ fontSize: "10pt", fontFamily: "Constantia" }}>
                  Established EDI feed building process that synchronized
                  company and insurance carrier efforts by defining prioritized
                  requirements for internal tools, automating business
                  procedures, and driving adoption with business user training.
                  Defined and documented all engineering and operational
                  procedures and architected custom SQL reports to promote data
                  integrity across databases.
                </span>
              </p>
              <p
                className="MsoNormal"
                style={{
                  marginTop: "3pt",
                  marginRight: "0in",
                  marginBottom: "0in",
                  marginLeft: "0.25in",
                  textIndent: "-13.5pt",
                }}
              >
                <span
                  style={{
                    fontSize: "10pt",
                    fontFamily: "Noto Sans Symbols",
                  }}
                >
                  &#9679;
                  <span style={{ font: "7pt Times New Roman" }}>
                    &nbsp;&nbsp;&nbsp;
                  </span>
                </span>
                <b>
                  <span
                    style={{
                      fontWeight: "bold",
                      fontSize: "10pt",
                      fontFamily: "Constantia",
                    }}
                  >
                    Team Leadership:{" "}
                  </span>
                </b>
                <span style={{ fontSize: "10pt", fontFamily: "Constantia" }}>
                  Directed daily activities of 13 technical analysts supporting
                  EDI and SQL data duties.
                </span>
              </p>
              <p
                className="MsoNormal"
                style={{
                  marginTop: "3pt",
                  marginRight: "0in",
                  marginBottom: "0in",
                  marginLeft: "0.25in",
                  textIndent: "-13.5pt",
                }}
              >
                <span
                  style={{
                    fontSize: "10pt",
                    fontFamily: "Noto Sans Symbols",
                  }}
                >
                  &#9679;
                  <span style={{ font: "7pt Times New Roman" }}>
                    &nbsp;&nbsp;&nbsp;
                  </span>
                </span>
                <b>
                  <span
                    style={{
                      fontWeight: "bold",
                      fontSize: "10pt",
                      fontFamily: "Constantia",
                    }}
                  >
                    Technical Leadership:{" "}
                  </span>
                </b>
                <span style={{ fontSize: "10pt", fontFamily: "Constantia" }}>
                  Won two internal hackathons as front-end engineer and back-end
                  engineer for an Amazon Web Services (AWS) Lambda Alexa
                  Integration that used Python and Node.js.
                </span>
              </p>
              <p
                className="MsoNormal"
                style={{
                  marginTop: "3pt",
                  marginRight: "0in",
                  marginBottom: "0in",
                  marginLeft: "0.25in",
                  textIndent: "-13.5pt",
                }}
              >
                <span
                  style={{
                    fontSize: "10pt",
                    fontFamily: "Noto Sans Symbols",
                  }}
                >
                  &#9679;
                  <span style={{ font: "7pt Times New Roman" }}>
                    &nbsp;&nbsp;&nbsp;
                  </span>
                </span>
                <b>
                  <span
                    style={{
                      fontWeight: "bold",
                      fontSize: "10pt",
                      fontFamily: "Constantia",
                    }}
                  >
                    Thought Leadership:{" "}
                  </span>
                </b>
                <span style={{ fontSize: "10pt", fontFamily: "Constantia" }}>
                  Facilitated skills-based training for 13 data analysis.
                </span>
              </p>
              <p
                className="MsoNormal"
                style={{
                  marginTop: "3pt",
                  marginRight: "0in",
                  marginBottom: "0in",
                  marginLeft: "0.25in",
                  textIndent: "-13.5pt",
                }}
              >
                <span
                  style={{
                    fontSize: "10pt",
                    fontFamily: "Noto Sans Symbols",
                  }}
                >
                  &#9679;
                  <span style={{ font: "7pt Times New Roman" }}>
                    &nbsp;&nbsp;&nbsp;
                  </span>
                </span>
                <b>
                  <span
                    style={{
                      fontWeight: "bold",
                      fontSize: "10pt",
                      fontFamily: "Constantia",
                    }}
                  >
                    Operational Excellence:{" "}
                  </span>
                </b>
                <span style={{ fontSize: "10pt", fontFamily: "Constantia" }}>
                  Completed 1K+ SQL CRUD operations.
                </span>
              </p>
              <br />
              <p className="MsoNormal" style={{ marginTop: "6pt" }}>
                <b>
                  <u>
                    <span
                      style={{
                        fontWeight: "bold",
                        fontSize: "10pt",
                        fontFamily: "Constantia",
                      }}
                    >
                      Agile Project Manager / Scrum Master
                    </span>
                  </u>
                </b>
                <span style={{ fontSize: "10pt", fontFamily: "Constantia" }}>
                  , 2015 to 2016
                </span>
              </p>
              <p
                className="MsoNormal"
                style={{ marginTop: "3pt", textAlign: "justify" }}
              >
                <span style={{ fontSize: "10pt", fontFamily: "Constantia" }}>
                  Delivered a portfolio of software development projects using
                  Agile methodologies to drive automated deployments, import
                  tool creation, payroll engineering support including
                  maintenance and bugs and custom reporting. Enabled high impact
                  decision making by the senior management team by regularly
                  assessing and reporting risks and facilitating ceremonies
                  including sprint planning, retros, and daily standups.
                </span>
              </p>
              <p
                className="MsoNormal"
                style={{
                  marginTop: "3pt",
                  marginRight: "0in",
                  marginBottom: "0in",
                  marginLeft: "0.25in",
                  textIndent: "-13.5pt",
                }}
              >
                <span
                  style={{
                    fontSize: "10pt",
                    fontFamily: "Noto Sans Symbols",
                  }}
                >
                  &#9679;
                  <span style={{ font: "7pt Times New Roman" }}>
                    &nbsp;&nbsp;&nbsp;
                  </span>
                </span>
                <b>
                  <span
                    style={{
                      fontWeight: "bold",
                      fontSize: "10pt",
                      fontFamily: "Constantia",
                    }}
                  >
                    Agile Environment:{" "}
                  </span>
                </b>
                <span style={{ fontSize: "10pt", fontFamily: "Constantia" }}>
                  Coached three engineering teams new to Agile methodology.
                </span>
              </p>
              <p
                className="MsoNormal"
                style={{
                  marginTop: "3pt",
                  marginRight: "0in",
                  marginBottom: "0in",
                  marginLeft: "0.25in",
                  textIndent: "-13.5pt",
                }}
              >
                <span
                  style={{
                    fontSize: "10pt",
                    fontFamily: "Noto Sans Symbols",
                  }}
                >
                  &#9679;
                  <span style={{ font: "7pt Times New Roman" }}>
                    &nbsp;&nbsp;&nbsp;
                  </span>
                </span>
                <b>
                  <span
                    style={{
                      fontWeight: "bold",
                      fontSize: "10pt",
                      fontFamily: "Constantia",
                    }}
                  >
                    Migration:{" "}
                  </span>
                </b>
                <span style={{ fontSize: "10pt", fontFamily: "Constantia" }}>
                  Transitioned project tracking from disparate sources into JIRA
                  by establishing projects and workflow.
                </span>
              </p>
              <p
                className="MsoNormal"
                style={{
                  marginTop: "3pt",
                  marginRight: "0in",
                  marginBottom: "0in",
                  marginLeft: "0.25in",
                  textIndent: "-13.5pt",
                }}
              >
                <span
                  style={{
                    fontSize: "10pt",
                    fontFamily: "Noto Sans Symbols",
                  }}
                >
                  &#9679;
                  <span style={{ font: "7pt Times New Roman" }}>
                    &nbsp;&nbsp;&nbsp;
                  </span>
                </span>
                <b>
                  <span
                    style={{
                      fontWeight: "bold",
                      fontSize: "10pt",
                      fontFamily: "Constantia",
                    }}
                  >
                    Operations Optimization:{" "}
                  </span>
                </b>
                <span style={{ fontSize: "10pt", fontFamily: "Constantia" }}>
                  Enabled business users to pull real-time data for urgent tasks
                  by building a custom script using NodeJS and JIRA s API.
                </span>
              </p>
              <p
                className="MsoNormal"
                style={{
                  marginTop: "3pt",
                  marginRight: "0in",
                  marginBottom: "0in",
                  marginLeft: "0.25in",
                  textIndent: "-13.5pt",
                }}
              >
                <span
                  style={{
                    fontSize: "10pt",
                    fontFamily: "Noto Sans Symbols",
                  }}
                >
                  &#9679;
                  <span style={{ font: "7pt Times New Roman" }}>
                    &nbsp;&nbsp;&nbsp;
                  </span>
                </span>
                <b>
                  <span
                    style={{
                      fontWeight: "bold",
                      fontSize: "10pt",
                      fontFamily: "Constantia",
                    }}
                  >
                    Agile Advocate:{" "}
                  </span>
                </b>
                <span style={{ fontSize: "10pt", fontFamily: "Constantia" }}>
                  Championed use of Agile, resulting in self-managed teams
                  within 10 months.
                </span>
              </p>
              <p
                className="MsoNormal"
                style={{
                  marginTop: "3pt",
                  marginRight: "0in",
                  marginBottom: "0in",
                  marginLeft: "0.25in",
                  textIndent: "-13.5pt",
                }}
              >
                <span
                  style={{
                    fontSize: "10pt",
                    fontFamily: "Noto Sans Symbols",
                  }}
                >
                  &#9679;
                  <span style={{ font: "7pt Times New Roman" }}>
                    &nbsp;&nbsp;&nbsp;
                  </span>
                </span>
                <b>
                  <span
                    style={{
                      fontWeight: "bold",
                      fontSize: "10pt",
                      fontFamily: "Constantia",
                    }}
                  >
                    Scripting Solution:{" "}
                  </span>
                </b>
                <span style={{ fontSize: "10pt", fontFamily: "Constantia" }}>
                  Automated updates hourly for critical tickets with a Nodejs
                  scripting solution.
                </span>
              </p>
              <p className="MsoNormal" style={{ marginTop: "12pt" }}>
                <span
                  style={{
                    fontWeight: "bold",
                    fontSize: "10pt",
                    fontFamily: "Constantia",
                  }}
                >
                  <br />
                  AMPLIFY EDUCATION 2012 to 2015
                </span>
              </p>
              <br />
              <p className="MsoNormal">
                <b>
                  <u>
                    <span
                      style={{
                        fontWeight: "bold",
                        fontSize: "10pt",
                        fontFamily: "Constantia",
                      }}
                    >
                      Consulting Project Manager / Scrum Master
                    </span>
                  </u>
                </b>
              </p>
              <p
                className="MsoNormal"
                style={{ marginTop: "3pt", textAlign: "justify" }}
              >
                <span style={{ fontSize: "10pt", fontFamily: "Constantia" }}>
                  Directed three simultaneous roadmaps across three development
                  teams with individual Product Owners to build system
                  components for an education technology firm. Engaged clients
                  through effective change management leadership and delivered
                  strategic reports to empower executive-level decision making.
                  Steered project success by centralizing project communication,
                  identifying and mitigating risks and engaging in tactical
                  resource planning that supported project profit and loss
                  (P&amp;L) goals. Mentored colleagues regarding Project
                  Management Office (PMO) best practices and controls.
                </span>
              </p>
              <p
                className="MsoNormal"
                style={{
                  marginTop: "3pt",
                  marginRight: "0in",
                  marginBottom: "0in",
                  marginLeft: "0.25in",
                  textIndent: "-13.5pt",
                }}
              >
                <span
                  style={{
                    fontSize: "10pt",
                    fontFamily: "Noto Sans Symbols",
                  }}
                >
                  &#9679;
                  <span style={{ font: "7pt Times New Roman" }}>
                    &nbsp;&nbsp;&nbsp;
                  </span>
                </span>
                <b>
                  <span
                    style={{
                      fontWeight: "bold",
                      fontSize: "10pt",
                      fontFamily: "Constantia",
                    }}
                  >
                    Agile Project Management:{" "}
                  </span>
                </b>
                <span style={{ fontSize: "10pt", fontFamily: "Constantia" }}>
                  Established and executed project plans in an Agile environment
                  integrating Scrum framework and running ceremonies to
                  facilitate standups, sprint planning and retrospectives.
                </span>
              </p>
              <p
                className="MsoNormal"
                style={{
                  marginTop: "3pt",
                  marginRight: "0in",
                  marginBottom: "0in",
                  marginLeft: "0.25in",
                  textIndent: "-13.5pt",
                }}
              >
                <span
                  style={{
                    fontSize: "10pt",
                    fontFamily: "Noto Sans Symbols",
                  }}
                >
                  &#9679;
                  <span style={{ font: "7pt Times New Roman" }}>
                    &nbsp;&nbsp;&nbsp;
                  </span>
                </span>
                <b>
                  <span
                    style={{
                      fontWeight: "bold",
                      fontSize: "10pt",
                      fontFamily: "Constantia",
                    }}
                  >
                    NYC Department of Education ARIS system
                  </span>
                </b>
                <span style={{ fontSize: "10pt", fontFamily: "Constantia" }}>
                  : Expanded role from Project Manager to Product Owner by
                  gathering requirements, crafting user stories, prioritizing
                  backlog and defining acceptance criteria using Kanban
                  techniques.
                </span>
              </p>
              <p
                className="MsoNormal"
                style={{
                  marginTop: "3pt",
                  marginRight: "0in",
                  marginBottom: "0in",
                  marginLeft: "0.25in",
                  textIndent: "-13.5pt",
                }}
              >
                <span
                  style={{
                    fontSize: "10pt",
                    fontFamily: "Noto Sans Symbols",
                  }}
                >
                  &#9679;
                  <span style={{ font: "7pt Times New Roman" }}>
                    &nbsp;&nbsp;&nbsp;
                  </span>
                </span>
                <b>
                  <span
                    style={{
                      fontWeight: "bold",
                      fontSize: "10pt",
                      fontFamily: "Constantia",
                    }}
                  >
                    National Education Project
                  </span>
                </b>
                <span style={{ fontSize: "10pt", fontFamily: "Constantia" }}>
                  : Oversaw the scope, schedule, budget, and resources for a
                  26-month $8M+ assignment by removing team blockages and
                  adapting schedule to deliver the entire project on-time with a
                  34% profit margin.
                </span>
              </p>
              <br />
              <p className="MsoNormal" style={{ marginTop: "12pt" }}>
                <span
                  style={{
                    fontWeight: "bold",
                    fontSize: "10pt",
                    fontFamily: "Constantia",
                  }}
                >
                  BANK OF AMERICA / MERRILL LYNCH (COLLABERA) 2011 to 2012
                </span>
              </p>
              <br />
              <p className="MsoNormal">
                <b>
                  <u>
                    <span
                      style={{
                        fontWeight: "bold",
                        fontSize: "10pt",
                        fontFamily: "Constantia",
                      }}
                    >
                      Business Analyst &amp; Project Manager
                    </span>
                  </u>
                </b>
              </p>
              <p
                className="MsoNormal"
                style={{ marginTop: "3pt", textAlign: "justify" }}
              >
                <span style={{ fontSize: "10pt", fontFamily: "Constantia" }}>
                  Commanded all aspects of the software development life cycle
                  (SDLC) for a global markets tech firm from requirements
                  gathering and development to quality assurance (QA), user
                  acceptance testing (UAT) and production support. Authored
                  business specifications documents to ensure quality creation
                  and maintenance of global applications using .NET and Java.
                </span>
              </p>
              <p
                className="MsoNormal"
                style={{
                  marginTop: "3pt",
                  marginRight: "0in",
                  marginBottom: "0in",
                  marginLeft: "0.25in",
                  textIndent: "-13.5pt",
                }}
              >
                <span
                  style={{
                    fontSize: "10pt",
                    fontFamily: "Noto Sans Symbols",
                  }}
                >
                  &#9679;
                  <span style={{ font: "7pt Times New Roman" }}>
                    &nbsp;&nbsp;&nbsp;
                  </span>
                </span>
                <b>
                  <span
                    style={{
                      fontWeight: "bold",
                      fontSize: "10pt",
                      fontFamily: "Constantia",
                    }}
                  >
                    Database Architecture:{" "}
                  </span>
                </b>
                <span style={{ fontSize: "10pt", fontFamily: "Constantia" }}>
                  Assembled custom SQL to obtain data as subject matter expert
                  (SME) for ETL and OBIEE.
                </span>
              </p>
              <p
                className="MsoNormal"
                style={{
                  marginTop: "3pt",
                  marginRight: "0in",
                  marginBottom: "0in",
                  marginLeft: "0.25in",
                  textIndent: "-13.5pt",
                }}
              >
                <span
                  style={{
                    fontSize: "10pt",
                    fontFamily: "Noto Sans Symbols",
                  }}
                >
                  &#9679;
                  <span style={{ font: "7pt Times New Roman" }}>
                    &nbsp;&nbsp;&nbsp;
                  </span>
                </span>
                <b>
                  <span
                    style={{
                      fontWeight: "bold",
                      fontSize: "10pt",
                      fontFamily: "Constantia",
                    }}
                  >
                    Project Management
                  </span>
                </b>
                <span style={{ fontSize: "10pt", fontFamily: "Constantia" }}>
                  : Coordinated project schedules and managed global stakeholder
                  relationships to better manage project scope for on-time
                  delivery of key client solutions.
                </span>
              </p>
              <p
                className="MsoNormal"
                style={{
                  marginTop: "3pt",
                  marginRight: "0in",
                  marginBottom: "0in",
                  marginLeft: "0.25in",
                  textIndent: "-13.5pt",
                }}
              >
                <span
                  style={{
                    fontSize: "10pt",
                    fontFamily: "Noto Sans Symbols",
                  }}
                >
                  &#9679;
                  <span style={{ font: "7pt Times New Roman" }}>
                    &nbsp;&nbsp;&nbsp;
                  </span>
                </span>
                <b>
                  <span
                    style={{
                      fontWeight: "bold",
                      fontSize: "10pt",
                      fontFamily: "Constantia",
                    }}
                  >
                    Business Intelligence (BI) Scheme
                  </span>
                </b>
                <span style={{ fontSize: "10pt", fontFamily: "Constantia" }}>
                  : Gathered technical requirements for Data Architect and
                  co-designed the BI scheme for Oracle Business Intelligence
                  project.
                </span>
              </p>
              <br />
              <p className="MsoNormal" style={{ marginTop: "12pt" }}>
                <span
                  style={{
                    fontWeight: "bold",
                    fontSize: "10pt",
                    fontFamily: "Constantia",
                  }}
                >
                  LENDER PROCESSING SERVICES 2010 to 2011
                </span>
              </p>
              <br />
              <p className="MsoNormal">
                <b>
                  <u>
                    <span
                      style={{
                        fontWeight: "bold",
                        fontSize: "10pt",
                        fontFamily: "Constantia",
                      }}
                    >
                      Project Manager
                    </span>
                  </u>
                </b>
              </p>
              <br />
              <span style={{ fontSize: "10pt", fontFamily: "Constantia" }}>
                Architected the on-time delivery of a 14-month SaaS
                enterprise-level content management application project
                including digitizing servicing mailroom for a mortgage
                technology services company. Governed team of business analysts
                and engineers while traveling to client sites for 25% of duties.
              </span>
              <p className="MsoNormal" style={{ marginTop: "12pt" }}>
                <span
                  style={{
                    fontWeight: "bold",
                    fontSize: "10pt",
                    fontFamily: "Constantia",
                  }}
                >
                  EVERBANK 2004 to 2010
                </span>
              </p>
              <br />
              <p className="MsoNormal">
                <b>
                  <u>
                    <span
                      style={{
                        fontWeight: "bold",
                        fontSize: "10pt",
                        fontFamily: "Constantia",
                      }}
                    >
                      Business &amp; Reporting Analyst
                    </span>
                  </u>
                </b>
              </p>
              <p
                className="MsoNormal"
                style={{ marginTop: "3pt", textAlign: "justify" }}
              >
                <span style={{ fontSize: "10pt", fontFamily: "Constantia" }}>
                  Supported business operations for a mortgage technology
                  division by documenting requirements for and building SQL
                  reports, administering QA and UAT functions and writing
                  functional specifications for a rules-based web service
                  pricing engine. Examined XML between applications and
                  engineered SQL reports that extracted data from Oracle and SQL
                  server databases. Drove mortgage loan productivity by
                  developing key performance indicators (KPIs).
                </span>
              </p>
              <p
                className="MsoNormal"
                style={{
                  marginTop: "3pt",
                  marginRight: "0in",
                  marginBottom: "0in",
                  marginLeft: "0.25in",
                  textIndent: "-13.5pt",
                }}
              >
                <span
                  style={{
                    fontSize: "10pt",
                    fontFamily: "Noto Sans Symbols",
                  }}
                >
                  &#9679;
                  <span style={{ font: "7pt Times New Roman" }}>
                    &nbsp;&nbsp;&nbsp;
                  </span>
                </span>
                <b>
                  <span
                    style={{
                      fontWeight: "bold",
                      fontSize: "10pt",
                      fontFamily: "Constantia",
                    }}
                  >
                    Operations Optimization
                  </span>
                </b>
                <span style={{ fontSize: "10pt", fontFamily: "Constantia" }}>
                  : Administered capacity-based incentive model and reduced
                  execution time from two weeks to three hours.
                </span>
              </p>
              <p
                className="MsoNormal"
                style={{
                  marginTop: "3pt",
                  marginRight: "0in",
                  marginBottom: "0in",
                  marginLeft: "0.25in",
                  textIndent: "-13.5pt",
                }}
              >
                <span
                  style={{
                    fontSize: "10pt",
                    fontFamily: "Noto Sans Symbols",
                  }}
                >
                  &#9679;
                  <span style={{ font: "7pt Times New Roman" }}>
                    &nbsp;&nbsp;&nbsp;
                  </span>
                </span>
                <b>
                  <span
                    style={{
                      fontWeight: "bold",
                      fontSize: "10pt",
                      fontFamily: "Constantia",
                    }}
                  >
                    Automation
                  </span>
                </b>
                <span style={{ fontSize: "10pt", fontFamily: "Constantia" }}>
                  : Developed custom VBA in Excel to automate business functions
                  for the production department.
                </span>
              </p>
              <p
                className="MsoNormal"
                style={{
                  marginTop: "3pt",
                  marginRight: "0in",
                  marginBottom: "0in",
                  marginLeft: "0.25in",
                  textIndent: "-13.5pt",
                }}
              >
                <span
                  style={{
                    fontSize: "10pt",
                    fontFamily: "Noto Sans Symbols",
                  }}
                >
                  &#9679;
                  <span style={{ font: "7pt Times New Roman" }}>
                    &nbsp;&nbsp;&nbsp;
                  </span>
                </span>
                <b>
                  <span
                    style={{
                      fontWeight: "bold",
                      fontSize: "10pt",
                      fontFamily: "Constantia",
                    }}
                  >
                    Business Leadership
                  </span>
                </b>
                <span style={{ fontSize: "10pt", fontFamily: "Constantia" }}>
                  : Expanded skill set through attainment of MBA before
                  returning to Analyst role.
                </span>
              </p>
              <p
                className="MsoNormal"
                style={{ marginTop: "12pt", textAlign: "justify" }}
              >
                <i>
                  <span
                    style={{
                      fontWeight: "bold",
                      fontSize: "10pt",
                      fontFamily: "Constantia",
                    }}
                  > 
                    Additional Experience:
                    <a name="_heading=h.30j0zll"></a>
                  </span>
                </i>
              </p>
              <p
                className="MsoNormal"
                style={{ marginLeft: "0.25in", textIndent: "-13.5pt" }}
              >
                <span
                  style={{
                    fontSize: "10pt",
                    fontFamily: "Noto Sans Symbols",
                  }}
                >
                  &#9679;
                  <span style={{ font: "7pt Times New Roman" }}>
                    &nbsp;&nbsp;&nbsp;
                  </span>
                </span>
                <b>
                  <span
                    style={{
                      fontWeight: "bold",
                      fontSize: "10pt",
                      fontFamily: "Constantia",
                    }}
                  >
                    Web Development Instructor
                  </span>
                </b>
                <span
                  style={{
                    fontWeight: "bold",
                    fontSize: "10pt",
                    fontFamily: "Constantia",
                  }}
                >
                  (2018 to 2019) | Columbia University / Trilogy Education, Inc.
                </span>
              </p>
              <p className="MsoNormal" style={{ marginLeft: ".5in" }}>
                <span style={{ fontSize: "10pt", fontFamily: "Courier New" }}>
                  <span style={{ font: "7pt Times New Roman" }}>
                    &nbsp;&nbsp;
                  </span>
                </span>
                <span style={{ fontSize: "10pt", fontFamily: "Constantia" }}>
                  Facilitated mastery of server-side technologies by adult
                  students through practical application and exercises involving
                  NodeJS, React, Firebase, MongoDB, MySQL, git, HTML5, CSS,
                  JavaScript, jQuery. Instructed students through three
                  full-stack web projects, resulting in ~80 students graduating.
                </span>
              </p>
            </td>
          </tr>
        </table>

        <div
          style={{
            border: "none",
            borderBottom: "solid #a68f0d 1.5pt",
            padding: "0in 0in 0in 0in",
          }}
        >
          <p className="MsoNormal" style={{ border: "none", padding: "0in" }}>
            <span style={{ fontSize: "1pt", fontFamily: "Constantia" }}>
              &nbsp;
            </span>
          </p>

          <p className="MsoNormal" style={{ border: "none", padding: "0in" }}>
            <span style={{ fontSize: "10pt", fontFamily: "Constantia" }}>
              &nbsp;
            </span>
          </p>
        </div>

        <table
          className="aa"
          border="1"
          cellspacing="0"
          cellpadding="0"
          width="720"
          style={{ borderCollapse: "collapse", border: "none" }}
        >
          <tr>
            <td
              width="720"
              valign="top"
              style={{
                width: "7.5in",
                border: "none",
                padding: "0in 5.75pt 0in 5.75pt",
              }}
            >
              <p className="MsoNormal" style={{ marginTop: "12pt" }}>
                <b>
                  <span
                    style={{
                      fontWeight: "bold",
                      fontSize: "10pt",
                      fontFamily: "Constantia",
                    }}
                  >
                    EDUCATION
                  </span>
                </b>
              </p>
              <p className="MsoNormal" style={{ marginTop: "6pt" }}>
                <b>
                  <span style={{ fontSize: "10pt", fontFamily: "Constantia" }}>
                    Master of Business Administration (MBA), Global Business
                  </span>
                </b>
                <a name="_heading=h.gjdgxs"></a>
                <span style={{ fontSize: "10pt", fontFamily: "Constantia" }}>
                  | University of North Florida, 2009
                </span>
              </p>
              <p className="MsoNormal" style={{ marginTop: "6pt" }}>
                <b>
                  <span style={{ fontSize: "10pt", fontFamily: "Constantia" }}>
                    Master of Arts, Intercultural Communication and
                    International Management
                  </span>
                </b>
                <span style={{ fontSize: "10pt", fontFamily: "Constantia" }}>
                  | University of Warsaw, 2009
                </span>
              </p>
              <p className="MsoNormal">
                <i>
                  <span style={{ fontSize: "10pt", fontFamily: "Constantia" }}>
                    Key Achievement: Earned two graduate degrees while studying
                    in the U.S., Germany, Poland and China.
                  </span>
                </i>
              </p>
              <p className="MsoNormal" style={{ marginTop: "6pt" }}>
                <b>
                  <span style={{ fontSize: "10pt", fontFamily: "Constantia" }}>
                    Bachelor of Business Administration (BBA), International
                    Business
                  </span>
                </b>
                <span style={{ fontSize: "10pt", fontFamily: "Constantia" }}>
                  (Cum Laude) | University of North Florida, 2002
                </span>
              </p>
              <p className="MsoNormal" style={{ marginTop: "6pt" }}>
                <b>
                  <span style={{ fontSize: "10pt", fontFamily: "Constantia" }}>
                    Certification
                  </span>
                </b>
                <span style={{ fontSize: "10pt", fontFamily: "Constantia" }}>
                  | Project Management Profession (PMP) Certificate # 1746809,
                  Member ID 2501650
                </span>
              </p>
              <p className="MsoNormal" style={{ marginTop: "6pt" }}>
                <b>
                  <span style={{ fontSize: "10pt", fontFamily: "Constantia" }}>
                    Certification
                  </span>
                </b>
                <span style={{ fontSize: "10pt", fontFamily: "Constantia" }}>
                  | Certified Scrum Master (CSM), Member ID 000449957
                </span>
              </p>
              <p className="MsoNormal" style={{ marginTop: "6pt" }}>
                <b>
                  <span style={{ fontSize: "10pt", fontFamily: "Constantia" }}>
                    Certification
                  </span>
                </b>
                <span style={{ fontSize: "10pt", fontFamily: "Constantia" }}>
                  | Scaled Agile Framework (SAFe) 5 Agilist, ID 37333023-1950
                </span>
              </p>
              <p className="MsoNormal" style={{ marginTop: "6pt" }}>
                <b>
                  <span style={{ fontSize: "10pt", fontFamily: "Constantia" }}>
                    Professional Development
                  </span>
                </b>
                <span style={{ fontSize: "10pt", fontFamily: "Constantia" }}>
                  | Certified in Spanish K-12 Instruction (Florida), Spanish
                  &amp; Spanish Culture at the University of Valencia (Summer
                  2001)
                </span>
              </p>
            </td>
          </tr>
        </table>

        <div
          style={{
            border: "none",
            borderBottom: "solid #a68f0d 1.5pt",
            padding: "0in 0in 0in 0in",
          }}
        >
          <p className="MsoNormal" style={{ border: "none", padding: "0in" }}>
            <span style={{ fontSize: "10pt", fontFamily: "Constantia" }}>
              &nbsp;
            </span>
          </p>
        </div>

        <table
          className="ab"
          border="1"
          cellspacing="0"
          cellpadding="0"
          width="720"
          style={{ borderCollapse: "collapse", border: "none" }}
        >
          <tr>
            <td
              width="720"
              valign="top"
              style={{
                width: "7.5in",
                border: "none",
                padding: "0in 5.75pt 0in 5.75pt",
              }}
            >
              <p
                className="MsoNormal"
                style={{ marginTop: "12pt", textAlign: "justify" }}
              >
                <b>
                  <span
                    style={{
                      fontWeight: "bold",
                      fontSize: "10pt",
                      fontFamily: "Constantia",
                    }}
                  >
                    BUSINESS / TECHNICAL PROFICIENCIES
                  </span>
                </b>
              </p>
              <table
                className="ac"
                border="1"
                cellspacing="0"
                cellpadding="0"
                width="720"
                style={{ borderCollapse: "collapse", border: "none" }}
              >
                <tr style={{ height: "25pt" }}>
                  <td
                    width="174"
                    valign="top"
                    style={{
                      width: "130.5pt",
                      border: "solid black 1pt",
                      padding: "0in 5.75pt 0in 5.75pt",
                      height: "25pt",
                    }}
                  >
                    <p
                      className="MsoNormal"
                      style={{
                        marginTop: "12pt",
                        marginRight: "0in",
                        marginBottom: "0in",
                        marginLeft: "-5.25pt",
                        marginBottom: "0.0001pt",
                      }}
                    >
                      <b>
                        <span
                          style={{
                            fontSize: "10pt",
                            fontFamily: "Constantia serif",
                          }}
                        >
                          Methodologies:
                        </span>
                      </b>
                    </p>
                  </td>
                  <td
                    width="546"
                    valign="bottom"
                    style={{
                      width: "409.5pt",
                      border: "solid black 1pt",
                      borderLeft: "none",
                      padding: "0in 5.75pt 0in 5.75pt",
                      height: "25pt",
                    }}
                  >
                    <p className="MsoNormal">
                      <span
                        style={{ fontSize: "10pt", fontFamily: "Constantia" }}
                      >
                        Scrum, Kanban, Waterfall, Agile
                      </span>
                    </p>
                  </td>
                </tr>
                <tr style={{ height: "25pt" }}>
                  <td
                    width="174"
                    valign="top"
                    style={{
                      width: "130.5pt",
                      border: "solid black 1pt",
                      borderTop: "none",
                      padding: "0in 5.75pt 0in 5.75pt",
                      height: "25pt",
                    }}
                  >
                    <p
                      className="MsoNormal"
                      style={{
                        marginTop: "12pt",
                        marginRight: "0in",
                        marginBottom: "0in",
                        marginLeft: "-5.25pt",
                      }}
                    >
                      <b>
                        <span
                          style={{
                            fontSize: "10pt",
                            fontFamily: "Constantia serif",
                          }}
                        >
                          Tools:
                        </span>
                      </b>
                    </p>
                  </td>
                  <td
                    width="546"
                    valign="bottom"
                    style={{
                      width: "409.5pt",
                      borderTop: "none",
                      borderLeft: "none",
                      borderBottom: "solid black 1pt",
                      borderRight: "solid black 1pt",
                      padding: "0in 5.75pt 0in 5.75pt",
                      height: "25pt",
                    }}
                  >
                    <p className="MsoNormal">
                      <span
                        style={{ fontSize: "10pt", fontFamily: "Constantia" }}
                      >
                        Atlassian Suite: JIRA Admin, Confluence, Slack, SQL,
                        SSRS, ETL, Data Warehousing, MS Office, MS Project,
                        Google Suite, VS Code, Visual Studio, Smartsheets
                      </span>
                    </p>
                  </td>
                </tr>
                <tr style={{ height: "14.5pt" }}>
                  <td
                    width="174"
                    valign="top"
                    style={{
                      width: "130.5pt",
                      border: "solid black 1pt",
                      borderTop: "none",
                      padding: "0in 5.75pt 0in 5.75pt",
                      height: "14.5pt",
                    }}
                  >
                    <p
                      className="MsoNormal"
                      style={{
                        marginTop: "1pt",
                        marginRight: "0in",
                        marginBottom: "1pt",
                        marginLeft: "-5.25pt",
                      }}
                    >
                      <b>
                        <span
                          style={{
                            fontSize: "10pt",
                            fontFamily: "Constantia serif",
                          }}
                        >
                          Programming Languages:
                        </span>
                      </b>
                    </p>
                  </td>
                  <td
                    width="546"
                    valign="bottom"
                    style={{
                      width: "409.5pt",
                      borderTop: "none",
                      borderLeft: "none",
                      borderBottom: "solid black 1pt",
                      borderRight: "solid black 1pt",
                      padding: "0in 5.75pt 0in 5.75pt",
                      height: "14.5pt",
                    }}
                  >
                    <p
                      className="MsoNormal"
                      style={{
                        marginTop: "1pt",
                        marginRight: "0in",
                        marginBottom: "1pt",
                        marginLeft: "0in",
                      }}
                    >
                      <span
                        style={{ fontSize: "10pt", fontFamily: "Constantia" }}
                      >
                        React, NodeJS, APIs, Express, JavaScript, Docker,
                        Kubernetes, Spinnaker, git, API s, HTML5, CSS3, SQL,
                        MongoDB, jQuery, VBA
                      </span>
                    </p>
                  </td>
                </tr>
                <tr style={{ height: "29.25pt" }}>
                  <td
                    width="174"
                    valign="top"
                    style={{
                      width: "130.5pt",
                      border: "solid black 1pt",
                      borderTop: "none",
                      padding: "0in 5.75pt 0in 5.75pt",
                      height: "29.25pt",
                    }}
                  >
                    <p
                      className="MsoNormal"
                      style={{
                        marginTop: "1pt",
                        marginRight: "0in",
                        marginBottom: "12pt",
                        marginLeft: "-5.25pt",
                      }}
                    >
                      <b>
                        <span
                          style={{
                            fontSize: "10pt",
                            fontFamily: "Constantia serif",
                          }}
                        >
                          Natural Languages:
                        </span>
                      </b>
                    </p>
                  </td>
                  <td
                    width="546"
                    valign="bottom"
                    style={{
                      width: "409.5pt",
                      borderTop: "none",
                      borderLeft: "none",
                      borderBottom: "solid black 1pt",
                      borderRight: "solid black 1pt",
                      padding: "0in 5.75pt 0in 5.75pt",
                      height: "29.25pt",
                    }}
                  >
                    <p
                      className="MsoNormal"
                      style={{
                        marginTop: "1pt",
                        marginRight: "0in",
                        marginBottom: "12pt",
                        marginLeft: "0in",
                      }}
                    >
                      <span
                        style={{ fontSize: "10pt", fontFamily: "Constantia" }}
                      >
                        English, Spanish as a second language (Formerly
                        certified to teach Spanish K-12 in Florida). Studied
                        Spanish and Spanish Culture at the University of
                        Valencia, Summer 2001
                      </span>
                    </p>
                  </td>
                </tr>
              </table>
              <p
                className="MsoNormal"
                style={{ marginTop: "4pt", textAlign: "justify" }}
              ></p>
            </td>
          </tr>
        </table>

        <p className="MsoNormal">
          <span style={{ fontFamily: "Constantia" }}>&nbsp;</span>
        </p>
      </div>
    </div>
  );
};

export default TopResume;
