import React from "react";
import "./MainContentBody.css";

const MainContentBody = (props) => {
  const { bodyContent, resetContent } = props;
  return (
    <div
      // className={`MainContent__Body ${
      //   resetContent === true ? "invisible" : "visible"
      // }`}
      className="MainContent__Body"
    >
      {bodyContent}
    </div>
  );
};

export default MainContentBody;
