import React from "react";
import MainContentHeader from "../../components/MainContentHeader/MainContentHeader";
import MainContentBody from "../../components/MainContentBody/MainContentBody";
import "./MainContent.css";

const MainContent = (props) => {
  const { activeContentID, resetContent, activeContent } = props;

  const {
    bodyContent,
    headerContent,
    altText,
    headerImage,
    imageClass,
  } = activeContent;
  return (
    <div className="MainContentContainer">
      <div
        className={`MainContent ${
          resetContent === true ? "invisible" : `visible`
        }`}
      >
        <MainContentHeader
          altText={altText}
          headerImage={headerImage}
          headerContent={headerContent}
          resetContent={resetContent}
          imageClass={imageClass}
          activeContentID={activeContentID}
        />
        <MainContentBody
          bodyContent={bodyContent}
          resetContent={resetContent}
        />
      </div>
    </div>
  );
};

export default MainContent;
