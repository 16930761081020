import React from "react";
import "./Rules.css";

const Rules = () => (
  <div>
    <p>
      <span className="Bold">Contribution: </span>I was the Creator, Product
      Manager, Designer and Front-End Developer for this SQL / Express / React /
      Node (SERN) application. I also wrote the back-end SQL queries that
      translated business logic into English for end users using nested CASE
      WHEN logic, and UNIONED queries against 8 different tables.
    </p>

    <p className="Bold">Business Accomplishments: </p>
    <ul style={{ padding: "0px 30px 25px" }}>
      <li>Total cycle time reduced from 2-5 days to 30 minutes.</li>
      <li>Moved 1.5 FTE from Operational work to Feature Engineering work.</li>
    </ul>
    <p>
      <span className="Bold">Design: </span>A Sprite Mario is animated using CSS
      animations. Namely's Engineering Department embraced a theme of retro
      video games, which inspired me for this app's design.
    </p>

    <p>
      <span className="Bold">Background: </span>At the time, any business logic
      necessary for employee benefits eligibility in Namely required an Engineer
      to perform CRUD operations directly against the database. Business users
      created many hundreds of "Rules" operations tickets for Engineering per
      year. This work was classified as Toil, or repetitive maintenace work that
      distracts Engineers from Feature development. Engineering was able to
      first reduce some of the toil by turning SQL queries against 8 diffferent
      tables into an aggregated Stored Procedure. From there, I wrote SQL
      reports to read and delete the data from the disparate sources, and
      further developed an Express back-end that would perform create operations
      using the Stored Proc. This allowed users a self-service model that
      reduced the hand-off, and thereby reduced total process time from 2-5 days
      to 30 minutes. I originally wrote this as a full-stack Javascript
      application in Node, but our Chief Architect requested that it be
      rewritten in C# to better integrate into our existing stack. I worked with
      a C# Engineer and oversaw the back-end refactor as the Product Manager for
      3 months, until we launched the site. I maintain the application as the
      Front-End engineer today.
    </p>
  </div>
);

export default Rules;
