import React from "react";
import "./Summary.css";
const mapListItems = (arr) => arr.map((item, i) => <li key={i}>{item}</li>);

const Summary = () => (
  <div className="Summary">
    <p>
      Hi, I'm Jordan. I'm a Product Manager and Scrum Master with a passion for
      developing highly performant Engineering teams, and building internal
      tools to automate work. I have extensive experience as a Product Manager,
      Project Manager, Scrum Master and a Web/SQL Developer. If you need an
      Agile Leader with both business and technical skills, then we would both
      benefit from an
      <span>
        <a href="../contact"> introductory conversation.</a>
      </span>
      <br />
      <br />I am adept at gathering and translating business requirements into
      user stories, synchronizing technology and operational team efforts, and
      leveraging advanced technical skills to create automation solutions and
      redefine what is possible. I coach my engineering teams through effective
      communication, mentorship, and skill-building training. I build
      sustainable processes that streamline product feature development and
      operations and to deliver measurable results. I look forward to hearing
      how I can help your business.
    </p>
    <div className="SummaryList">
      <ul>
        {mapListItems([
          "Product Management",
          "Change Management",
          "Client Engagement",
          "Stakeholder Relationship Management",
          "Software Development",
          "Life Cycle (SDLC)",
        ])}
      </ul>
      <ul>
        {mapListItems([
          "Agile Project Management",
          "Technical Operations Management",
          "Team Leadership & Management",
          "Requirements Gathering",
          "Automation Solution Development",
        ])}
      </ul>
      <ul>
        {mapListItems([
          "Scrum Framework",
          "Agile Methodologies",
          "Business Analysis",
          "Information Technology",
          "Risk Assessment & Reporting",
        ])}
      </ul>
    </div>
  </div>

  //   <ul>
  //     {[
  //       `Senior Product and Project Leader with Agile Engineering and Operational
  // expertise`,
  //       `Product Owner for internal tools. Drives requirements,
  //     prioritizes backlog and delivers`,
  //       `CSM and PMP with a project management
  //     background in software development`,
  //       `Developer and Part-time Web Dev
  //     Instructor that designs and builds automation solutions`,
  //       `Builds efficient,
  //     sustainable processes that streamline project and operations work`,
  //       `Experience building, coaching and managing technical teams to
  //     results-driven success `,
  //     ].map((item, i) => (
  //       <li key={i}>{item}</li>
  //     ))}
  //   </ul>
);

export default Summary;
