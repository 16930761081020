import React from "react";
import { Switch, Route } from "react-router-dom";
import VR from "../../NavBarPages/VR/VR";
import "./RouterUnderNavBar.css";
import Portfolio from "../../NavBarPages/Portfolio/Portfolio";
import Contact from "../../NavBarPages/Contact/Contact";
import ObjectResume from "../../NavBarPages/Resume/Resume";
import TopResume from "../../Content/TopResume";

const RouterUnderNavBar = (props) => {
  const {
    activeContentID,
    activeContent,
    setActiveContentID,
    resetContent,
    setResetContent,
  } = props;

  return (
    <div className="RouterUnderNavBar">
      <Switch>
        <Route path="/vr">
          <VR />
        </Route>

        <Route path="/resume">
          <TopResume />
        </Route>

        <Route path="/contact">
          <Contact />
        </Route>

        <Route path="/">
          <Portfolio
            activeContentID={activeContentID}
            activeContent={activeContent}
            setActiveContentID={setActiveContentID}
            resetContent={resetContent}
            setResetContent={setResetContent}
          />
        </Route>
      </Switch>
    </div>
  );
};

export default RouterUnderNavBar;
