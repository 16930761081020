import React from "react";
import NamelyTrilogyNight from "../img/hires/Namely-Trilogy-Night.jpg";
import Columbia from "../img/hires/Columbia.JPG";
import Hamrah from "../img/hires/Hamrah.jpg";
import Students from "../img/hires/Students.jpg";
import Students2 from "../img/hires/Students2.jpg";
import Discussion from "../img/hires/Discussion.jpg";
import JordanBuffaloe from "../img/hires/Jordan Buffaloe.jpg";
import "./Trilogy.css";

const Trilogy = (props) => {
  return (
    <div className="Trilogy">
      <p>
        I had the privilege to teach Full-stack Web Development to students at{" "}
        <a href="https://bootcamp.cvn.columbia.edu/coding/">
          Columbia University's Bootcamp part-time 24-week program.
        </a>{" "}
        <br />
        <br />
        <div className="Center">
          <img
            className="MainContent__Header__headerImage"
            src={Columbia}
            alt="Columbia University"
          />
        </div>
        <br />
        I later had the honor to watch them begin to receive their first jobs as
        new Developers and have kept in touch with them along the way. We still
        have an active Slack channel where they may ask questions about SQL /
        Javascript / best practices, or just reach out for encouragement. <br />
        <br />I am passionate about teaching and believe in going the extra mile
        to ensure that my students understood the subject material, spending
        many hours after class and on the weekends for extra tutoring. During my
        first 24-week cohort, I was also able to introduce my students to my
        coworkers by organizing a Namely Meet-and-Greet Engineering night, where
        my students met experienced Namely Engineers. Below are some images from
        that event.
      </p>
      <br />
      <div className="Center">
        <img
          className="Trilogy__Image-small"
          src={NamelyTrilogyNight}
          alt="Namely Engineering Meet and Greet"
          title="Namely Engineering Meet and Greet"
        />
        <img
          className="Trilogy__Image-small"
          src={Hamrah}
          alt="Former Chief Architect, Michael Hamrah"
          title="Former Chief Architect, Michael Hamrah"
        />
        <img
          className="Trilogy__Image-small"
          src={Students}
          alt="Bootcamp Students meeting with Namely Engineers"
          title="Bootcamp Students meeting with Namely Engineers"
        />
        <img
          className="Trilogy__Image-small"
          src={JordanBuffaloe}
          alt="Jordan Buffaloe hosting the event"
          title="Jordan Buffaloe hosting the event"
        />
        <img
          className="Trilogy__Image-small"
          src={Students2}
          alt="Bootcamp Students meeting with Namely Engineers"
          title="Bootcamp Students meeting with Namely Engineers"
        />
        <img
          className="Trilogy__Image-small"
          src={Discussion}
          alt="Students talking with Engineer, Steve Ognibene"
          title="Students talking with Engineer, Steve Ognibene"
        />
        <div className="Center Bold">
          <p>Photo Cred: Jason Miguel</p>
        </div>
      </div>
    </div>
  );
};

export default Trilogy;
