import React from "react";
import Summary from "./Summary";
import AgileAndTeams from "./AgileAndTeams";
import Rules from "./Rules";
import DataServicesDashboard from "./DataServicesDashboard";
import PortfolioContent from "./PortfolioContent";
import Hackathons from "./Hackathons";
import Trilogy from "./Trilogy";

import headshot from "../img/Jordan Headshot 2021.png";
import NamelyRulesScreenshot from "../img/hires/Namely-Rules-1.png";
import DSDScreenshot from "../img/hires/DSD.png";
import PortfolioSiteScreenshot from "../img/hires/Portfolio-Site.png";
import HackathonsImage from "../img/hires/Hackathons.png";
import TrilogyImage from "../img/hires/Trilogy.png";
import PayrollBirthday from "../img/hires/PayrollBirthday.gif";

// import headshot from "../img/headshot.png";
// import NamelyRulesScreenshot from "../img/Namely-Rules-1.PNG";
// import DSDScreenshot from "../img/DSD.PNG";
// import PortfolioSiteScreenshot from "../img/Portfolio-Site.png";
// import ScrumProcess from "../img/ScrumProcess.JPG";
// import Trilogy from "../img/Trilogy.JPG";
// import HackathonsImage from "../img/Hackathons.PNG";

export default [
  {
    id: 0,
    title: "Summary",
    headerContent: "Senior Product Manager, MBA + Web/SQL Developer",
    headerImage: headshot,
    imageClass: "MainContent__Header__Landing",
    altText: null,
    bodyContent: <Summary />,
  },
  {
    id: 1,
    title: "Agile and Teams",
    headerContent: "Introducing Teams to Agile Processes",
    headerImage: PayrollBirthday,
    imageClass: "MainContent__Header__headerImage",
    altText: null,
    bodyContent: <AgileAndTeams />,
  },
  {
    id: 2,
    title: "Web Dev Instruction",
    headerContent: "Graduating Students to Full-Time Javascript Development",
    headerImage: TrilogyImage,
    imageClass: "MainContent__Header__headerImage",
    altText: null,
    bodyContent: <Trilogy />,
  },
  {
    id: 3,
    title: "Namely Rules",
    headerContent:
      "Reduced total process time by days. CRUD React / C# .NET / SQL Server App modeled after Super Mario Brothers",
    headerImage: NamelyRulesScreenshot,
    imageClass: "MainContent__Header__headerImage",
    altText: "Namely Rules Screenshot",
    bodyContent: <Rules />,
  },
  {
    id: 4,
    title: "Data Services Dashboard",
    headerContent:
      "React / Node / SQL Server App using JIRA and Salesforce API's",
    headerImage: DSDScreenshot,
    imageClass: "MainContent__Header__headerImage",
    altText: "DSD Screenshot",
    bodyContent: <DataServicesDashboard />,
  },
  {
    id: 5,
    title: "Hackathons",
    headerContent: "Pulling all nighters for Innovation",
    headerImage: HackathonsImage,
    imageClass: "MainContent__Header__headerImage",
    altText: "Hackathon Screenshots",
    bodyContent: <Hackathons />,
  },
  {
    id: 6,
    title: "Portfolio Site",
    headerContent: "Recursion(x => Recursion(x))",
    headerImage: PortfolioSiteScreenshot,
    imageClass: "MainContent__Header__headerImage",
    altText: "Portfolio Site Screenshot",
    bodyContent: <PortfolioContent />,
  },
];
