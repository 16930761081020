import React from "react";
import BusinessCard from "../../components/BusinessCard/BusinessCard";
import RecommendationCard from "../../components/RecommendationCard/RecommendationCard";
import Recommendations from "../../Content/Recommendations";
import "./Contact.css";

const Contact = (props) => {
  return (
    <div className="Contact">
      <div className="BusinessCardContactContainer">
        <BusinessCard />
      </div>
      <div className="RecommendationCardContactContainer">
        <h1>Recommendations</h1>
        {Recommendations.map((r, i) => (
          <RecommendationCard
            key={`recommendation__${i}`}
            recommender={r.recommender}
            recommenderLink={r.recommenderLink}
            recommenderTitle={r.recommenderTitle}
            recommenderRelationship={r.recommenderRelationship}
            recommenderImage={r.recommenderImage}
            recommenderImageStyle={r.recommenderImageStyle}
            recommendationText={r.recommendationText}
          />
        ))}
      </div>
    </div>
  );
};

export default Contact;
