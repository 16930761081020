import React, { useState } from "react";
import "./App.css";
import NavBar from "./components/NavBar/NavBar";
import RouterUnderNavBar from "./components/RouterUnderNavBar/RouterUnderNavBar";
import { BrowserRouter as Router } from "react-router-dom";
import Content from "./Content";

function App() {
  const [activeContentID, setActiveContentID] = useState(0);
  const [resetContent, setResetContent] = useState(false);
  const activeContent = Content.filter((c) => c.id === activeContentID)[0];

  return (
    <Router className="Background">
      <NavBar className="Background" />
      <RouterUnderNavBar
        className="Background"
        activeContentID={activeContentID}
        setActiveContentID={setActiveContentID}
        resetContent={resetContent}
        setResetContent={setResetContent}
        activeContent={activeContent}
      />
      <div className="Void" />
    </Router>
  );
}

export default App;
