import React from "react";
import MainContent from "../../components/MainContent/MainContent";
import SideBar from "../../components/SideBar/SideBar";
import Badges from "../../components/Badges/Badges";
import "./Portfolio.css";

const Portfolio = (props) => {
  const {
    activeContentID,
    setActiveContentID,
    resetContent,
    setResetContent,
    activeContent,
  } = props;

  return (
    <div className="Portfolio">
      <SideBar
        activeContentID={activeContentID}
        setActiveContentID={setActiveContentID}
        setResetContent={setResetContent}
      />
      <MainContent
        activeContentID={activeContentID}
        activeContent={activeContent}
        resetContent={resetContent}
      />
      <Badges resetContent={resetContent} />
    </div>
  );
};

export default Portfolio;
