import React from "react";
import "./RecommendationCard.css";

const RecommendationCard = (props) => {
  const {
    id,
    recommender,
    recommenderLink,
    recommenderTitle,
    recommenderRelationship,
    recommenderImage,
    recommenderImageStyle,
    recommendationText,
  } = props;
  return (
    <div className="RecommendationCard">
      <div className="RecommendationCard__photo">
        <img src={recommenderImage} alt={`${recommender}`} />
      </div>
      <div className="RecommendationCard__recommendation">
        <p className="RecommendationCard__recommender">
          <a href={recommenderLink} target="_blank" rel="noopener noreferrer">
            {recommender}
          </a>
        </p>
        <p className="Bold RecommendationCard__recommenderTitle">
          {recommenderTitle} and {recommenderRelationship}
        </p>
        <p>{recommendationText}</p>
      </div>
    </div>
  );
};

export default RecommendationCard;
