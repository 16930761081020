import React from "react";
import aframe from "aframe";
import registerClickDrag from "aframe-click-drag-component";
import "./VR.css";
registerClickDrag(aframe);

const VR = (props) => {
  return (
    <div className="Embedded">
      <a-scene embedded>
        <a-sphere position="0 1.25 -5" radius="1.25" color="#EF2D5E"></a-sphere>
        <a-entity
          geometry="primitive: plane; width: 4; height: auto"
          material="color: blue"
          text="value: This text will be 4 units wide."
          position="-1 0.5 -3"
        ></a-entity>
        <a-cylinder
          position="1 0.75 -3"
          radius="0.5"
          height="1.5"
          color="#FFC65D"
        ></a-cylinder>
        <a-plane
          position="0 0 -4"
          rotation="-90 0 0"
          width="4"
          height="4"
          color="#7BC8A4"
        ></a-plane>
        <a-sky color="#ECECEC"></a-sky>
      </a-scene>
    </div>
  );
};

export default VR;
