import React from "react";
import "./Hackathons.css";
import NamelySensePhoto from "../img/Sense.jpg";
import Sensor from "../img/Sensor.JPG";
import Sensor2 from "../img/Sensor2.JPG";

const Hacakthons = (props) => {
  return (
    <div className="Hackathons">
      <p>
        Hackathons taught me the value of having a vision and being singular,
        focused and driven until its complete. I still achieve many of many
        projects by pulling late night focus sessions. I find it extremely
        valuable for a team to eradicate other noise, meet with a vision in
        mind, and collaborate until it becomes a reality. The following were
        award-winning 24-hour hackathon projects in which I participated.
      </p>
      <div className="NamelyPresence">
        <h2 className="Bold Underline">I. Namely Presence</h2>
        <p>
          <span className="Bold">Summary: </span>
          <span className="Italics">
            'As an employee, I'd like facial recognition to sign me in for the
            day, so that I do not forget to punch in, or report my time
            incorrectly.'
          </span>{" "}
          Namely Presence used facial recognition to detect employees and
          identify them for several potential purposes:
        </p>
        <ul>
          <li>Seamless security entry into building</li>
          <li>Seamless time tracking to clock in and out</li>
          <li>Employee office presence tracking</li>
        </ul>
        <p>
          We were able to add an Alexa and Slackbot integration that allowed
          Employees to ask if their coworker was present in the offie for that
          day.
        </p>
        <p>
          <span className="Bold">Achievement: </span>Winner: “Moonshot” Award
          (photo above)
        </p>
        <br />
        <p>
          <span className="Bold">My Contribution: </span>
          <span className="Italics">
            AWS Lambda Engineer, JQL Queries, JIRA API Integration, Slack
            webhook integration
          </span>
        </p>
        <br />
        <p>
          <span className="Bold">Tech Stack:</span>
          <ul>
            <li>AWS Lambda</li>
            <li>JIRA API</li>
            <li>Amazon Alexa Integration</li>
            <li>Dynamo DB</li>
            <li>Web Camera</li>
          </ul>
        </p>
        <h3 className="Bold">Contributors:</h3>
        <ul>
          <li>Rich Fernandez</li>
          <li>Jordan Buffaloe</li>
          <li>Eli Raymond</li>
          <li>Victoria Mei</li>
          <li>Orkhan Mamedov</li>
          <li>Sarah Ostrom</li>
        </ul>
      </div>
      <div className="NamelySense">
        <h2 className="Bold Underline">II. Namely Sense</h2>
        <img
          className="MainContent__Header__headerImage"
          style={{ marginLeft: "5%", borderRadius: "5%" }}
          alt="Namely Sense Hackathon Room"
          src={NamelySensePhoto}
        />
        {/* <span>
          <img
            className="Hackathons__image-sideBySide"
            style={{ borderRadius: "5%" }}
            alt="Sensor from project"
            src={Sensor}
          />
        </span>
        <span>
          <img
            className="Hackathons__image-sideBySide"
            style={{ borderRadius: "5%" }}
            alt="Another sensor from project"
            src={Sensor2}
          />
        </span> */}
        <p>
          <span className="Bold">Achievement: </span>Winner of the Sequoia
          Capital “Moonshot” Award
        </p>
        <br />
        <p>
          <span className="Bold">My Contribution: </span>
          <span className="Italics">
            Project Manager and Front-End Engineer
          </span>
        </p>
        <br />
        <p>
          <span className="Bold">Summary: </span>
          <span className="Italics">
            'As an employee that needs a conference room right now, I would like
            to know whether a "booked" room is actually in use, so that I may
            find a suitable space for my meeting.'
          </span>{" "}
          Namely Sense detected the presence of people in conference rooms,
          using both motion detection and temperature detection sensors. It
          reported findings back to a centralized dashboard that was accessible
          to Namely employees. It included the list of Conference Rooms. Green =
          Open. Red = Taken.
        </p>
        <h3 className="Bold">Contributors:</h3>
        <ul>
          <li>Gregory Delpe</li>
          <li>Jordan Buffaloe</li>
          <li>Rich Fernandez</li>
          <li>Attila Maczak</li>
          <li>Luigi Patruno</li>
        </ul>
        <p>
          Below are the notes from our actual hackthon. I've called out some{" "}
          <span className="Highlights">fun highlights.</span> To read more about
          Hackathons at Namely, you can visit{" "}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://blog.namely.com/blog/2016/6/2/inside-namelys-first-hackathon-the-winners"
          >
            the Namely Blog.
          </a>
        </p>
        <br />
        <p>
          <span className="Bold">Tech Stack:</span>
          <ul>
            <li>Breadboards and sensors</li>
            <li>RabbitMQ for messaging from devices</li>
            <li>LUA for device programming</li>
            <li>EJS Templates for Front-End</li>
          </ul>
        </p>
      </div>
      <body className="NamelySenseGoogleDoc c21">
        <h2 className="c15" id="h.j0433413gfcb">
          <span className="c5">Stories: </span>
        </h2>
        <ol className="c9 lst-kix_80gfnmsdh8e7-0 start" start="1">
          <li className="c2">
            <span className="c0">
              As a meeting conference room User, I would like to be able to see
              the availability of any conference room on the 15th Floor in real
              time on one page so that I may take advantage of free spaces
            </span>
          </li>
        </ol>
        <p className="c8">
          <span className="c0">Acceptance Criteria: </span>
        </p>
        <ul className="c9 lst-kix_aulkdkfyvnnj-0 start">
          <li className="c2">
            <span className="c0">
              Shades will be red for any conference room that has had activity
              in the last 2 minutes
            </span>
          </li>
          <li className="c2">
            <span className="c0">
              Shades will be green for any conference room that has NOT had
              activity in the last 2 minutes
            </span>
          </li>
        </ul>
        <p className="c8 c11">
          <span className="c0"></span>
        </p>
        <ol className="c9 lst-kix_80gfnmsdh8e7-0" start="2">
          <li className="c2">
            <span className="c0">
              As a meeting conference room User, I would like to be able to see
              the temperature of any conference room in real time so that I may
              gauge the future comfort of my participants
            </span>
          </li>
        </ol>
        <p className="c8">
          <span className="c0">Acceptance Criteria: </span>
        </p>
        <ul className="c9 lst-kix_aulkdkfyvnnj-0">
          <li className="c2">
            <span className="c0">
              Temperature and humidity display for each conference room{" "}
            </span>
          </li>
        </ul>
        <p className="c8 c11">
          <span className="c0"></span>
        </p>
        <ol className="c9 lst-kix_80gfnmsdh8e7-0" start="3">
          <li className="c2">
            <span className="c0">
              I would like to see the historic activity for motion, temperature
              and humidity for any conference room over time
            </span>
          </li>
        </ol>
        <p className="c8">
          <span className="c0">Acceptance Criteria: </span>
        </p>
        <ul className="c9 lst-kix_k50olb6cirkf-0 start">
          <li className="c2">
            <span className="c0">
              Be able to filter by day, past week, up to at least 1 month ago.
            </span>
          </li>
        </ul>

        <h2 className="c15" id="h.crxl2r5z3035">
          <span className="c5">
            Say that we did iteration (after finding out about Round Robin) -
            didn&rsquo;t include weather - we wanted our own home-grown product
            and to save $3K per year) and testing!
          </span>
        </h2>
        <p className="c8">
          <span className="c0">Integration w Slack?! </span>
        </p>
        <p className="c8">
          <span className="c0">/office temp</span>
        </p>
        <p className="c8">
          <span className="c0">/temp</span>
        </p>
        <p className="c8 c11">
          <span className="c0"></span>
        </p>
        <p className="c8">
          <span className="c0">/book Lincoln today 4:30PM</span>
        </p>
        <p className="c8">
          <span className="c0">/book</span>
        </p>
        <p className="c8 c11">
          <span className="c0"></span>
        </p>
        <p className="c8">
          <span>
            First Come, First Serve, Law of the Jungle. Meeting Rooms
            aren&rsquo;t cancelled.{" "}
          </span>
        </p>
        <h2 className="c15" id="h.8g8c7v4ei6pm">
          <span className="c5">Judging Criteria: </span>
        </h2>
        <p className="c20">
          <span className="c17 c26">&nbsp;1. Business Value (25%):</span>
        </p>
        <ul className="c9 lst-kix_d5do35ge0o3x-0 start">
          <li className="c7">
            <span className="c17 c16">
              Ability to describe specific pain point(s) around problem space
              and how their solution addresses these.
            </span>
          </li>
        </ul>
        <ul className="c9 lst-kix_d5do35ge0o3x-1 start">
          <li className="c3">
            <span className="c6">
              Finding an available room for a meeting can be problematic for
              startups and established companies alike. Small office spaces with
              limited rooms, combined with a growing staff can mean limited
              availability. Additionally, many meetings are required to keep a
              startup running smoothly. The ability for any user to set a
              recurring series of meetings{" "}
            </span>
          </li>
        </ul>
        <ul className="c9 lst-kix_d5do35ge0o3x-0">
          <li className="c7">
            <span className="c17 c16">
              Defined use case that matches articulated pain points for problem
              space.
            </span>
          </li>
        </ul>
        <ul className="c9 lst-kix_d5do35ge0o3x-1 start">
          <li className="c3">
            <span className="c6">
              All meeting rooms are booked for a future event. TBA or
              &ldquo;We&rsquo;ll find a space&rdquo; is often the default at
              this office. Sometimes spaces free up, sometimes they do not.{" "}
            </span>
          </li>
          <li className="c3">
            <span className="c6">
              Impromptu Meeting Needed. ARGH: All Conference Rooms are booked. I
              need to find a place to go quickly. I don&rsquo;t have time to use
              the meeting finder in Google Drive.{" "}
            </span>
          </li>
          <li className="c3">
            <span className="c6">
              See a chart of availability. Ideally check a certain date to see
              whether or not a standing meeting actually happened last time
            </span>
          </li>
        </ul>
        <ul className="c9 lst-kix_d5do35ge0o3x-0">
          <li className="c7">
            <span className="c17 c16">
              Demonstrate how the proposed solution impacts the problem space.
            </span>
          </li>
        </ul>
        <ul className="c9 lst-kix_d5do35ge0o3x-1 start">
          <li className="c3">
            <span className="c6">Maybe a short video or acted out example</span>
          </li>
        </ul>
        <p className="c20">
          <span className="c17 c26">2. Innovation &nbsp;(25%):</span>
        </p>
        <ul className="c9 lst-kix_hxvi2qvw7kvb-0 start">
          <li className="c7">
            <span className="c17 c16">
              Has this problem been solved before by anyone else?
            </span>
          </li>
        </ul>
        <ul className="c9 lst-kix_hxvi2qvw7kvb-1 start">
          <li className="c3">
            <span className="c16">Robin Presence: </span>
            <span className="c16 c27">
              Robin powered spaces use Bluetooth beacons to let the mobile app
              know when you are nearby. Once a room knows you&#39;re there, it
              updates the team and calendars automatically. It&rsquo;s meeting
              room scheduling in a snap.{" "}
            </span>
            <span className="c19">
              Calendars update reservations when people enter and exit meeting
              rooms, giving back extra time at the end.
            </span>
          </li>
        </ul>
        <ul className="c9 lst-kix_hxvi2qvw7kvb-0">
          <li className="c7">
            <span className="c29">
              How original is this solution compared to existing in the
              industry?
            </span>
          </li>
        </ul>
        <ul className="c9 lst-kix_hxvi2qvw7kvb-1 start">
          <li className="c3 c11">
            <span className="c14"></span>
          </li>
        </ul>
        <ul className="c9 lst-kix_hxvi2qvw7kvb-0">
          <li className="c7">
            <span className="c17 c16">
              How creative was the team in finding ways to use existing
              technology, features, or data to produce a new solution?
            </span>
          </li>
        </ul>
        <p className="c20">
          <span className="c16">&nbsp;</span>
          <span className="c17 c26">3. Execution and Delivery (25%)</span>
        </p>
        <ul className="c9 lst-kix_c4kx25kvtrii-0 start">
          <li className="c7">
            <span className="c17 c16">
              How much of a realized solution did they deliver?
            </span>
          </li>
        </ul>
        <ul className="c9 lst-kix_wgos134f4rvn-0 start">
          <li className="c7">
            <span className="c17 c16">
              How well was the project scope defined and adjusted based on
              Time/Resources available for the event?
            </span>
          </li>
          <li className="c7">
            <span className="c17 c16">
              How well did they make you really grasp how this solved the pain
              point or problem for the user during the demo?
            </span>
          </li>
        </ul>
        <p className="c20">
          <span className="c17 c26">4. Technical Feasibility (15%):</span>
        </p>
        <ul className="c9 lst-kix_bm8ydm7t1qpu-0 start">
          <li className="c7">
            <span className="c17 c16">Feasibility of implementation</span>
          </li>
          <li className="c7">
            <span className="c17 c16">
              Solution provided is technically sound
            </span>
          </li>
          <li className="c7">
            <span className="c17 c16">
              Next steps of the solution are laid out
            </span>
          </li>
        </ul>
        <p className="c20">
          <span className="c17 c26">
            5. Collaboration and Multidisciplinary (10%)
          </span>
        </p>
        <ul className="c9 lst-kix_x0lff8jx46oc-0 start">
          <li className="c7">
            <span className="c16 c17">
              How varied were the backgrounds of the team members?
            </span>
          </li>
        </ul>
        <ul className="c9 lst-kix_x0lff8jx46oc-1 start">
          <li className="c3">
            <span className="c6">Project Manager</span>
          </li>
          <li className="c3">
            <span className="c6">Accountant</span>
          </li>
          <li className="c3">
            <span className="c6">Data Import Engineer (Python)</span>
          </li>
          <li className="c3">
            <span className="c6">Data Import Engineer (Python)</span>
          </li>
          <li className="c3">
            <span className="c6">
              Core Services Architect acting as a hardware SME{" "}
            </span>
          </li>
        </ul>
        <ul className="c9 lst-kix_x0lff8jx46oc-0">
          <li className="c7">
            <span className="c17 c16">
              How well did the team collaborate in the presentation?
            </span>
          </li>
        </ul>
        <ul className="c9 lst-kix_x0lff8jx46oc-1 start">
          <li className="c3">
            <span className="c6">
              Save a speaking part for each of the guys
            </span>
          </li>
        </ul>
        <p className="c8 c11">
          <span className="c0"></span>
        </p>
        <p className="c8 c11">
          <span className="c0"></span>
        </p>
        <p className="c8 c11">
          <span className="c0"></span>
        </p>
        <p className="c8">
          <span className="c24">Documentation</span>
        </p>
        <p className="c8 c11">
          <span className="c0"></span>
        </p>
        <p className="c8">
          <span className="c0">12:30</span>
        </p>
        <ul className="c9 lst-kix_2xgovgskwcdx-0 start">
          <li className="c2">
            <span className="c0">
              Food, room setup, pictures, discussion of our goals. Spirits are
              high. We are feeling ambitious. We plan to win, but we also want
              to be company heroes, solving real Namely problems before industry
              ones.{" "}
            </span>
          </li>
        </ul>
        <p className="c8 c11">
          <span className="c0"></span>
        </p>
        <p className="c8">
          <span className="c0">1:00PM</span>
        </p>
        <ul className="c9 lst-kix_rbcl76p5ato0-0 start">
          <li className="c2">
            <span className="c0">We set a team goal and individual goals:</span>
          </li>
        </ul>
        <ul className="c9 lst-kix_rbcl76p5ato0-1 start">
          <li className="c8 c10">
            <span className="c0">By 2PM: </span>
          </li>
        </ul>
        <ul className="c9 lst-kix_rbcl76p5ato0-2 start">
          <li className="c8 c25">
            <span className="c0">
              By 2PM, One sensor in the Kent conference room will send
              temperature data to the broker (Mosquito) and a service (Sensely)
              will consume this data on a local server. The environment for the
              UI will be configured and UI development for our loose prototype
              will have started.{" "}
            </span>
          </li>
        </ul>
        <ul className="c9 lst-kix_rbcl76p5ato0-3 start">
          <li className="c4">
            <span className="c0">
              Greg and Attila will work on the hardware for the sensor
            </span>
          </li>
          <li className="c4">
            <span className="c0">
              Attila will configure the local server for Luigi
            </span>
          </li>
          <li className="c4">
            <span className="c0">
              Rich will create the env for the UI and begin UI development
            </span>
          </li>
          <li className="c4">
            <span className="c0">
              Luigi will create the local service (Sensely) to consume the data
            </span>
          </li>
          <li className="c4">
            <span className="c0">
              Jordan will prepare the documentation for the presentation with a
              barebones PPTX presentation, and document our progress throughout
            </span>
          </li>
        </ul>
        <ul className="c9 lst-kix_rbcl76p5ato0-2">
          <li className="c8 c25">
            <span className="c0">
              By 12AM, All Sensors installed in their respective locations (15
              conference rooms + 4 additional locations, time permitting)
            </span>
          </li>
        </ul>
        <ul className="c9 lst-kix_rbcl76p5ato0-3 start">
          <li className="c4">
            <span className="c0">Nook inside Library</span>
          </li>
          <li className="c4">
            <span className="c0">Nook inside Kitchen / Pool Table</span>
          </li>
          <li className="c4">
            <span className="c0">Library</span>
          </li>
          <li className="c4">
            <span className="c0">Kitchen</span>
          </li>
        </ul>
        <p className="c8 c11">
          <span className="c0"></span>
        </p>
        <p className="c8">
          <span className="c0">2:00PM</span>
        </p>
        <p className="c8">
          <span className="c0">PROGRESS REPORT: </span>
        </p>
        <ul className="c9 lst-kix_nw2cxupuup6d-0 start">
          <li className="c2">
            <span className="c0">
              Luigi: Met Goal - Python accepts data and can print it out.{" "}
            </span>
          </li>
          <li className="c2">
            <span className="c0">
              Atilla and Greg: Did not Meet Goal. Sending data but not
              temperature data yet. Needs another hour.{" "}
            </span>
          </li>
          <li className="c2">
            <span className="c0">
              Rich: Met Goal. Environment is setup. Sent CREATE tables.{" "}
            </span>
          </li>
          <li className="c2">
            <span className="c0">
              Jordan. Did not meet goal. Need more time for barebones
              presentation with additional scope of documenting process.{" "}
            </span>
          </li>
        </ul>
        <p className="c8">
          <span className="c0">GOALS FOR NEXT HOUR:</span>
        </p>
        <ul className="c9 lst-kix_czya9fs0uk08-0 start">
          <li className="c2">
            <span className="c0">
              Don&rsquo;t forget to report on Team Goal
            </span>
          </li>
          <li className="c2">
            <span className="c0">
              Luigi: Create a database. &nbsp;Use Rich&rsquo;s script to create
              tables. Initialize the tables and infrastructure. Temp data
              stretch goal is motion data.{" "}
            </span>
          </li>
          <li className="c2">
            <span className="c0">
              Rich will Merge to Master. Agree to model. Create mock data.
              Exploratory Visuals.{" "}
            </span>
          </li>
          <li className="c2">
            <span className="c0">
              Jordan will continue on presentation in the context of business
              goals, which will be a work in progress. Choose template and
              prepare opening slides, rather than creating whole barebones
              presentation, allow to evolve.{" "}
            </span>
          </li>
          <li className="c2">
            <span className="c0">
              We agreed to keep iterations at 1 hour as opposed to 2 to keep
              pressure high.{" "}
            </span>
          </li>
        </ul>
        <p className="c8 c11">
          <span className="c0"></span>
        </p>
        <p className="c8">
          <span className="c0">3:00PM </span>
        </p>
        <p className="c8">
          <span className="c0">PROGRESS REPORT:</span>
        </p>
        <ul className="c9 lst-kix_t3luv8y9d5rh-0 start">
          <li className="c2">
            <span className="c0">Team Goal: Not Met</span>
          </li>
          <li className="c2">
            <span className="c0">
              Atilla and Greg: Goal not met. But they connect to WIFI and to a
              local machine with Sensor.{" "}
            </span>
          </li>
          <li className="c2">
            <span className="c0">
              Luigi: Goal not met. Create Database. Database working locally on
              Docker. Have agreement on initial data model.{" "}
            </span>
          </li>
          <li className="c2">
            <span className="c0">
              Rich: Goal not met. Reviewed wireframes for the UI and established
              endpoints. Found good chart to use for reports. Will write down.{" "}
            </span>
          </li>
        </ul>
        <p className="c8">
          <span className="c0">GOALS FOR NEXT HOUR:</span>
        </p>
        <ul className="c9 lst-kix_t3luv8y9d5rh-0">
          <li className="c2">
            <span className="c0">Greg: To connect locally. </span>
          </li>
          <li className="c2">
            <span className="c0">
              Rich: JINJA will swap out text for charts. More design on how
              it&rsquo;s going to work.
            </span>
          </li>
          <li className="c2">
            <span className="c0">Atilla and Greg: TEMP DATA Is goal. </span>
          </li>
          <li className="c2">
            <span className="c0">
              Luigi: Will now create tables and write persistence functions to
              save incoming data to the database.{" "}
            </span>
          </li>
          <li className="c2">
            <span className="c0">
              Rich: Reviewed wireframes for the UI and established endpoints.
              Found good chart to use for reports. Will write down.{" "}
            </span>
          </li>
          <li className="c2">
            <span className="c0">Goals for next hour: </span>
          </li>
          <li className="c2">
            <span className="c0">Greg: To connect locally. </span>
          </li>
          <li className="c2">
            <span className="c0">
              Rich: JINJA will swap out text for charts. More design on how its
              going to work{" "}
            </span>
          </li>
        </ul>
        <p className="c8 c11">
          <span className="c0"></span>
        </p>
        <p className="c8">
          <span className="c0">4:00PM</span>
        </p>
        <p className="c8">
          <span className="c0">PROGRESS REPORT:</span>
        </p>
        <ul className="c9 lst-kix_ha5qmdvcb4si-0 start">
          <li className="c2">
            <span className="c0">
              Attila and greg: Met goal. Sending temp, humidity and motion.
              Initial tests seem to pass.{" "}
            </span>
          </li>
          <li className="c2">
            <span className="c0">
              Luigi: Not met. Wrote the tables but persistence functions not
              created. Wrote the flask skeleton for the GUI.{" "}
            </span>
          </li>
          <li className="c2">
            <span className="c0">
              Rich. Not met. Connected to postgresDB locally.{" "}
            </span>
          </li>
          <li className="c2">
            <span className="Highlights">
              Jordan: Presentation coming along with media and a dolphin to
              represent our mascot &ldquo;Sensely&rdquo;. Scope creep from Boss!{" "}
            </span>
          </li>
        </ul>
        <p className="c8 c11">
          <span className="c0"></span>
        </p>
        <p className="c8">
          <span className="c0">GOALS FOR THE NEXT HOUR:</span>
        </p>
        <ul className="c9 lst-kix_u8tjog263o2l-0 start">
          <li className="c2">
            <span className="c0">
              Luigi: Keep working on skeleton for the flask app. See what data
              format the data comes into. Figure out data transfer model.{" "}
            </span>
          </li>
          <li className="c2">
            <span className="c0">
              Attila and Greg: Setup the rest of the sensors and send to topics
              rather than sending raw data.{" "}
            </span>
          </li>
          <li className="c2">
            <span className="c0">
              Rich: Creating dummy data to feed the visuals. Stretch mock ups
              for one room.{" "}
            </span>
          </li>
        </ul>
        <p className="c8 c11">
          <span className="c0"></span>
        </p>
        <p className="c8">
          <span className="c0">5:00PM</span>
        </p>
        <p className="c8">
          <span className="c0">PROGRESS REPORT:</span>
        </p>
        <ul className="c9 lst-kix_48sqb0eak1ob-0 start">
          <li className="c2">
            <span className="c0">
              Rich: Not met. Halfway done with populating dummy data
            </span>
          </li>
          <li className="c2">
            <span className="c0">Luigi: Met goal. </span>
          </li>
          <li className="c2">
            <span className="c0">Atilla: Met goal of sending topics. </span>
          </li>
        </ul>
        <p className="c8 c11">
          <span className="c0"></span>
        </p>
        <p className="c8">
          <span className="c0">
            SET TEAM GOAL: BETWEEN 8-9, DEPLOY DATA TO A SERVER. HAVE 5 SENSORS
            TO PLACE IN ROOMS.{" "}
          </span>
        </p>
        <p className="c8">
          <span className="c0">GOALS FOR THE NEXT HOUR:</span>
        </p>
        <ul className="c9 lst-kix_srudydmdpi45-0 start">
          <li className="c2">
            <span className="c0">
              Rich: To share with team in reports format
            </span>
          </li>
          <li className="c2">
            <span className="c0">
              Luigi: Conform the data collecting python scripts to the data
              transfer model and starting to persist data to the database. At
              least some data will be persisted.{" "}
            </span>
          </li>
          <li className="c2">
            <span className="c0">
              Attila and Greg: Send timestamp for all measurements.{" "}
            </span>
          </li>
        </ul>
        <p className="c8 c11">
          <span className="c0"></span>
        </p>
        <p className="c8">
          <span className="c0">6:00PM</span>
        </p>
        <p className="c8">
          <span className="c0">PROGRESS REPORT:</span>
        </p>
        <ul className="c9 lst-kix_rz8nd6k90owi-0 start">
          <li className="c2">
            <span className="c0">
              We decided to extend this Sprint because dinner was served at
              5:30PM. No one chose to add scope to their extended Sprint.
              <span className="Highlights">SHAME.</span>{" "}
            </span>
          </li>
          <li className="c2">
            <span className="c0">
              Note: We are finding problems with the timestamp being generated
              from these sensors. They do not produce consistent results.{" "}
            </span>
          </li>
        </ul>
        <p className="c8">
          <span className="c0">
            GOALS FOR THE NEXT HOUR: Finish up last iteration.{" "}
          </span>
        </p>
        <p className="c8 c11">
          <span className="c0"></span>
        </p>
        <p className="c8">
          <span className="c0">7:00PM</span>
        </p>
        <p className="c8">
          <span className="c0">PROGRESS REPORT:</span>
        </p>
        <ul className="c9 lst-kix_k20156ckxm8s-0 start">
          <li className="c2">
            <span className="c0">Atilla And Greg: Met Goal</span>
          </li>
          <li className="c2">
            <span className="c0">
              Luigi: Met Goal. All data is being persisted to PostgresDB if it
              meets the agreed upon data model. Error handling on additional
              data.{" "}
            </span>
          </li>
          <li className="c2">
            <span className="c0">Rich: Did not yet meet goal</span>
          </li>
        </ul>
        <p className="c8">
          <span className="c0">GOALS FOR THE NEXT HOUR:</span>
        </p>
        <ul className="c9 lst-kix_68mkr2t9zt34-0 start">
          <li className="c2">
            <span className="c0">
              Attila and Greg: Setup the Server and setup 5 more sensors.
              Confirm that 3 work over the next hour.{" "}
            </span>
          </li>
          <li className="c2">
            <span className="c0">
              Luigi: Developing the flask application. Google authentication
              will be complete and can serve 10 data points to the screen as
              text.{" "}
            </span>
          </li>
          <li className="c2">
            <span className="c0">Rich: Reports Demo</span>
          </li>
        </ul>
        <p className="c8 c11">
          <span className="c0"></span>
        </p>
        <p className="c8">
          <span className="c0">8:00PM</span>
        </p>
        <p className="c8">
          <span className="c0">PROGRESS REPORT:</span>
        </p>
        <ul className="c9 lst-kix_i25eulm3wxvl-0 start">
          <li className="c2">
            <span className="c0">
              Attila and Greg: Goal Met. 3 work over the hour.{" "}
            </span>
          </li>
          <li className="c2">
            <span className="c0">Luigi: Goal met. </span>
          </li>
          <li className="c2">
            <span className="c0">Rich: Goal not met yet. Close. </span>
          </li>
        </ul>
        <p className="c8 c11">
          <span className="c0"></span>
        </p>
        <p className="c8">
          <span className="c0">GOALS FOR THE NEXT HOUR:</span>
        </p>
        <ul className="c9 lst-kix_i25eulm3wxvl-0">
          <li className="c2">
            <span className="c0">
              Attila and Greg: Will put up 4 sensors in conference rooms and
              test ideal placement.
            </span>
          </li>
          <li className="c2">
            <span className="c0">
              Luigi: Data collection script to be more robust. &nbsp;
            </span>
          </li>
          <li className="c2">
            <span className="c0">Rich: By 9PM will have reports ready. </span>
          </li>
          <li className="c2">
            <span className="c0">Jordan to revise presentation. </span>
          </li>
        </ul>
        <p className="c8 c11">
          <span className="c0"></span>
        </p>
        <p className="c8">
          <span className="c0">9:00PM</span>
        </p>
        <p className="c8">
          <span className="c0">PROGRESS REPORT:</span>
        </p>
        <ul className="c9 lst-kix_y8m1hau9zzz0-0 start">
          <li className="c2">
            <span className="c0">
              Atilla and Greg: Met goal. Now need other 3 to work. Stretch goal:
              Setup more sensors.{" "}
            </span>
          </li>
          <li className="c2">
            <span className="c0">
              Luigi: Not Done. We can add sensors without the script crashing.
              Won&rsquo;t crash. Need to alter data model.{" "}
            </span>
          </li>
          <li className="c2">
            <span className="c0">
              Rich: For motion whether sensors. Make sure it fills missing data
              with zeroes for visualization.{" "}
            </span>
          </li>
          <li className="c2">
            <span className="c0">
              Jordan: Failed. Update the presentation continues. Made some
              progress.{" "}
            </span>
          </li>
          <li className="c2">
            <span className="c0">
              Notes: People seemed to need a break here.{" "}
            </span>
          </li>
          <li className="c2">
            <span className="c0">
              BALANCES:&rdquo; Give me a number from 1 to 10 that represents
              your enthusiasm and energy level&rdquo;{" "}
            </span>
          </li>
        </ul>
        <ul className="c9 lst-kix_y8m1hau9zzz0-1 start">
          <li className="c8 c10">
            <span className="c0">Luigi: 10</span>
          </li>
          <li className="c8 c10">
            <span className="c0">Atilla: 10</span>
          </li>
          <li className="c8 c10">
            <span className="c0">Greg: 11</span>
          </li>
          <li className="c8 c10">
            <span className="c0">Rich: 10</span>
          </li>
          <li className="c8 c10">
            <span className="c0">Jordan: 10 </span>
          </li>
        </ul>
        <p className="c8 c11">
          <span className="c0"></span>
        </p>
        <p className="c8">
          <span className="c0">GOALS FOR THE NEXT HOUR:</span>
        </p>
        <p className="c8 c11">
          <span className="c0"></span>
        </p>
        <p className="c8">
          <span className="c0">10:00PM</span>
        </p>
        <p className="c8">
          <span className="c0">PROGRESS REPORT:</span>
        </p>
        <ul className="c9 lst-kix_896k3g3ixq2n-0 start">
          <li className="c2">
            <span className="c0">
              Atila and Greg. Not met. All except 1 is working. 4 more rooms.{" "}
            </span>
          </li>
          <li className="c2">
            <span className="c0">
              Luigi failed. Much problems. Such frustration. Get it working.{" "}
            </span>
          </li>
          <li className="c2">
            <span className="c0">Atila will work on breathlyzer. </span>
          </li>
          <li className="c2">
            <span className="c0">
              Greg will build 4 more as previously built and try to get Noble
              working.{" "}
            </span>
          </li>
          <li className="c2">
            <span className="c0">
              Rich: Fix the chart that he created with mock data. Circle back
              once we have more visuals.{" "}
            </span>
          </li>
        </ul>
        <p className="c8 c11">
          <span className="c0"></span>
        </p>
        <p className="c8">
          <span className="c0">GOALS FOR THE NEXT HOUR:</span>
        </p>
        <ul className="c9 lst-kix_896k3g3ixq2n-0">
          <li className="c2">
            <span className="c0">
              Atila and Greg. Not met. All except 1 is working. 4 more rooms.{" "}
            </span>
          </li>
          <li className="c2">
            <span className="c0">
              Luigi failed. Much problems. Such frustration. Get it working.{" "}
            </span>
          </li>
          <li className="c2">
            <span className="c0">Atila will work on breathlyzer. </span>
          </li>
          <li className="c2">
            <span className="c0">
              Greg will build 4 more as previously built and try to get Noble
              working.{" "}
            </span>
          </li>
          <li className="c2">
            <span className="c0">
              Rich: Fix the chart that he created with mock data. Circle back
              once we have more visuals.{" "}
            </span>
          </li>
        </ul>
        <p className="c8 c11">
          <span className="c0"></span>
        </p>
        <p className="c8 c11">
          <span className="c0"></span>
        </p>
        <p className="c8">
          <span className="c0">11:00PM</span>
        </p>
        <p className="c8">
          <span className="c0">PROGRESS REPORT:</span>
        </p>
        <ul className="c9 lst-kix_hj7pkip0tgxt-0 start">
          <li className="c2">
            <span className="c0">
              Greg: Not Met. One Sensor. Goal is to do one more{" "}
            </span>
          </li>
          <li className="c2">
            <span className="c0">
              Attila: Breathalyzer not working. Push to server for{" "}
            </span>
          </li>
          <li className="c2">
            <span className="c0">Luigi: Getting there. Not met. </span>
          </li>
          <li className="c2">
            <span className="c0">Rich: Get the divs working</span>
          </li>
        </ul>
        <p className="c8">
          <span className="c0">GOALS FOR THE NEXT HOUR:</span>
        </p>
        <p className="c8">
          <span className="c0">TEAM GOAL: UI by 2AM</span>
        </p>
        <p className="c8 c11">
          <span className="c0"></span>
        </p>
        <p className="c8">
          <span className="c0">12:00AM</span>
        </p>
        <p className="c8">
          <span className="c0">PROGRESS REPORT:</span>
        </p>
        <p className="c8">
          <span className="c0">
            Atilla: De-Scope the breathalyzer. Pushing to the server is causing
            problems. How often these things go down. Goal is saving to
            Postgres. Server is setup and we have been sending data points
            there.{" "}
          </span>
        </p>
        <p className="c8">
          <span className="c0">
            Luigi: No sensor data is being received. Goal is to persist the
            data.{" "}
          </span>
        </p>
        <p className="c8">
          <span className="c0">GOALS FOR THE NEXT HOUR:</span>
        </p>
        <p className="c8 c11">
          <span className="c0"></span>
        </p>
        <p className="c8 c11">
          <span className="c0"></span>
        </p>
        <p className="c8">
          <span className="c0">3:00AM</span>
        </p>
        <p className="c8">
          <span className="c0">PROGRESS REPORT:</span>
        </p>
        <ul className="c9 lst-kix_37go6g7zza71-0 start">
          <li className="c2">
            <span className="c0">Atilla: Asleep</span>
          </li>
          <li className="c2">
            <span className="Highlights">
              Luigi: Taking a break. 3rd redbull. Doesn&rsquo;t even drink
              redbull.Losin 2
            </span>
          </li>
          <li className="c2">
            <span className="c0">
              Greg: Troubleshooting non-working devices
            </span>
          </li>
          <li className="c2">
            <span className="c0">
              Rich: Validating data model, queries and previous data{" "}
            </span>
          </li>
          <li className="c2">
            <span className="c0">Jordan: Tired. Worked on CSS for UI. </span>
          </li>
        </ul>
        <p className="c8 c11">
          <span className="c0"></span>
        </p>
        <p className="c8">
          <span className="c0">4:26AM: </span>
        </p>
        <ul className="c9 lst-kix_gpdwx8hug7j8-0 start">
          <li className="c2">
            <span className="c0">
              Greg figured out how to get the sensors to re-connect when they
              lose connection, but only one can be on at one time
            </span>
          </li>
          <li className="c2">
            <span className="c0">
              Jordan: Working on Google API integration using my own namely
              calendar credentials
            </span>
          </li>
        </ul>
        <p className="c8 c11">
          <span className="c0"></span>
        </p>
        <p className="c8">
          <span className="c0">5:54AM</span>
        </p>
        <ul className="c9 lst-kix_sk7y82vgsp5q-0 start">
          <li className="c2">
            <span className="c0">
              Jordan still trying with GCAL API.{" "}
              <span className="Highlights">
                Everyone else is sleeping. Maybe I should be as well.{" "}
              </span>
              Atilla and Greg got something working 30 minutes ago, but
              sustained success is unclear.{" "}
            </span>
          </li>
        </ul>
        <p className="c8 c11">
          <span className="c0"></span>
        </p>
        <a id="t.dca3914c40c88c594ca7f692327ee6ed422e6595"></a>
        <a id="t.0"></a>
        <table className="c28">
          <tbody>
            <tr className="c12">
              <td className="c13 c18" colspan="1" rowspan="1">
                <p className="c1">
                  <span className="c23">Sensor Number</span>
                </p>
              </td>
              <td className="c13 c18" colspan="1" rowspan="1">
                <p className="c1">
                  <span className="c23">Room</span>
                </p>
              </td>
              <td className="c13 c18" colspan="1" rowspan="1">
                <p className="c1">
                  <span className="c23">Type</span>
                </p>
              </td>
            </tr>
            <tr className="c12">
              <td className="c13" colspan="1" rowspan="1">
                <p className="c1">
                  <span className="c0">1</span>
                </p>
              </td>
              <td className="c13" colspan="1" rowspan="1">
                <p className="c1">
                  <span className="c0">Kent</span>
                </p>
              </td>
              <td className="c13" colspan="1" rowspan="1">
                <p className="c1">
                  <span className="c0">Motion + Temperature</span>
                </p>
              </td>
            </tr>
            <tr className="c12">
              <td className="c13" colspan="1" rowspan="1">
                <p className="c1">
                  <span className="c0">2</span>
                </p>
              </td>
              <td className="c13" colspan="1" rowspan="1">
                <p className="c1">
                  <span className="c0">Milton</span>
                </p>
              </td>
              <td className="c13" colspan="1" rowspan="1">
                <p className="c1">
                  <span className="c0">Motion + Temperature</span>
                </p>
              </td>
            </tr>
            <tr className="c12">
              <td className="c13" colspan="1" rowspan="1">
                <p className="c1">
                  <span className="c0">3</span>
                </p>
              </td>
              <td className="c13" colspan="1" rowspan="1">
                <p className="c1">
                  <span className="c0">Ash</span>
                </p>
              </td>
              <td className="c13" colspan="1" rowspan="1">
                <p className="c1">
                  <span className="c0">Motion + Temperature</span>
                </p>
              </td>
            </tr>
            <tr className="c12">
              <td className="c13" colspan="1" rowspan="1">
                <p className="c1">
                  <span className="c0">4</span>
                </p>
              </td>
              <td className="c13" colspan="1" rowspan="1">
                <p className="c1">
                  <span className="c0">Box</span>
                </p>
              </td>
              <td className="c13" colspan="1" rowspan="1">
                <p className="c1">
                  <span className="c0">Motion + Temperature</span>
                </p>
              </td>
            </tr>
            <tr className="c12">
              <td className="c13" colspan="1" rowspan="1">
                <p className="c1">
                  <span className="c0">6</span>
                </p>
              </td>
              <td className="c13" colspan="1" rowspan="1">
                <p className="c1">
                  <span className="c0">Clay</span>
                </p>
              </td>
              <td className="c13" colspan="1" rowspan="1">
                <p className="c1">
                  <span className="c0">Motion + Temperature</span>
                </p>
              </td>
            </tr>
            <tr className="c12">
              <td className="c13" colspan="1" rowspan="1">
                <p className="c1">
                  <span className="c0">7</span>
                </p>
              </td>
              <td className="c13" colspan="1" rowspan="1">
                <p className="c1">
                  <span className="c0">Java</span>
                </p>
              </td>
              <td className="c13" colspan="1" rowspan="1">
                <p className="c1">
                  <span className="c0">Motion + Temperature</span>
                </p>
              </td>
            </tr>
            <tr className="c12">
              <td className="c13" colspan="1" rowspan="1">
                <p className="c1">
                  <span className="c0">8</span>
                </p>
              </td>
              <td className="c13" colspan="1" rowspan="1">
                <p className="c1">
                  <span className="c0">India</span>
                </p>
              </td>
              <td className="c13" colspan="1" rowspan="1">
                <p className="c1">
                  <span className="c0">Motion + Temperature</span>
                </p>
              </td>
            </tr>
            <tr className="c12">
              <td className="c13" colspan="1" rowspan="1">
                <p className="c1">
                  <span className="c0">9</span>
                </p>
              </td>
              <td className="c13" colspan="1" rowspan="1">
                <p className="c1">
                  <span className="c0">Freeman</span>
                </p>
              </td>
              <td className="c13" colspan="1" rowspan="1">
                <p className="c1">
                  <span className="c0">Motion + Temperature</span>
                </p>
              </td>
            </tr>
            <tr className="c12">
              <td className="c13" colspan="1" rowspan="1">
                <p className="c1">
                  <span className="c0">10</span>
                </p>
              </td>
              <td className="c13" colspan="1" rowspan="1">
                <p className="c1">
                  <span className="c0">Lincoln</span>
                </p>
              </td>
              <td className="c13" colspan="1" rowspan="1">
                <p className="c1">
                  <span className="c0">Motion + Temperature</span>
                </p>
              </td>
            </tr>
            <tr className="c12">
              <td className="c13" colspan="1" rowspan="1">
                <p className="c1">
                  <span className="c0">11</span>
                </p>
              </td>
              <td className="c13" colspan="1" rowspan="1">
                <p className="c1">
                  <span className="c0">Front Desk</span>
                </p>
              </td>
              <td className="c13" colspan="1" rowspan="1">
                <p className="c1">
                  <span className="c0">Temperature</span>
                </p>
              </td>
            </tr>
            <tr className="c12">
              <td className="c13" colspan="1" rowspan="1">
                <p className="c1">
                  <span className="c0">12</span>
                </p>
              </td>
              <td className="c13" colspan="1" rowspan="1">
                <p className="c1">
                  <span className="c0">Kitchen</span>
                </p>
              </td>
              <td className="c13" colspan="1" rowspan="1">
                <p className="c1">
                  <span className="c0">Temperature</span>
                </p>
              </td>
            </tr>
            <tr className="c12">
              <td className="c13" colspan="1" rowspan="1">
                <p className="c1">
                  <span className="c0">13</span>
                </p>
              </td>
              <td className="c13" colspan="1" rowspan="1">
                <p className="c1">
                  <span className="c0">Library</span>
                </p>
              </td>
              <td className="c13" colspan="1" rowspan="1">
                <p className="c1">
                  <span className="c0">Temperature</span>
                </p>
              </td>
            </tr>
          </tbody>
        </table>
        <p className="c8 c11">
          <span className="c0"></span>
        </p>
        <p className="c8">
          <span className="c0">7:23: </span>
        </p>
        <ul className="c9 lst-kix_oewobgvgj1c8-0 start">
          <li className="c2">
            <span className="Highlights">
              We have 9 sensors up and they are all reporting data!{" "}
            </span>
          </li>
          <li className="c2">
            <span className="c0">
              Jordan abandoned GCAL API integration because it must be validated
              using OAUTH, which requires that we&rsquo;re running FLASK, which
              Jordan cannot do due to Postgres dependencies
            </span>
          </li>
        </ul>
        <p className="c8">
          <span className="c0">10:15AM</span>
        </p>
        <p className="c8">
          <span>
            We are stoked that the sensors all seem to be working, with only
            occasional glitches! The first time we saw our green buttons turn to
            red upon us entering Lincoln was exciting for all of us! We are on
            to creating a Slack integration, which we believe will increase
            usability and provide a sexy factor, as well as connecting to our
            reporting that Rich has built. Our stretch goal is to also send a
            post request via OAUTH to book a 30 minute meeting via a button.{" "}
          </span>
        </p>
      </body>
    </div>
  );
};

export default Hacakthons;
