import React from "react";
import ScrumProcess from "../img/ScrumProcess.JPG";

const AgileAndTeams = (props) => (
  <div>
    <p>
      <span className="Bold">'Agile is effective' (says the PMP): </span>
      Like many of the rest of you PMP's, my transition to an Agile mindset was
      initially a difficult one. How will contracts work? Where is the timeline?
      I had run successful waterfall projects in the past, and still believe in
      tailoring the methodology to the project. Yet, I had to admit - most of my
      development projects were only successful after numerous change controls
      along the way. The Scope, Schedule and Budget documents were iterating,
      and the "sure bet" project plans developed at the beginning of my projects
      were bound to change.
      <br />
      <br />
      <div className="Bold Center">
        Ask me about my presentation on Embracing Business Agility
      </div>
      <br />
      <div
        className="AgileAndTeams__Presentation"
        style={{
          position: "relative",
          width: "100%",
          height: "0",
          paddingBottom: "calc(56.25% + 40px)",
        }}
      >
        <iframe
          className="AgileAndTeams__Presentation_iframe"
          title="Beautiful AI Presentation on Embracing Agile"
          allowfullscreen
          style={{
            position: "absolute",
            width: "100%",
            height: "100%",
            border: "solid 1px #333",
          }}
          src={`https://www.beautiful.ai/player/-M5oV5ow_L6PbN5Jezcs/Embracing-Business-Agility?utm_source=beautiful_player&utm_medium=embed&utm_campaign=-M2Pkt9pdw6jbwLdxJ2r`}
        ></iframe>
      </div>
      <br />
      <span className="Bold">Agile Transformation: </span>I was first hired on
      to Namely's PMO to help implement Agile and JIRA as the standard for our
      Product Engineering teams. After about 12 months of our PMO coaching teams
      alongside the guideance of an Agile adoption firm, we Project Managers
      changed focus toward Product and Operations. I also made updates as a JIRA
      Administrator that customizes workflows, screens, statuses and transitions
      for my teams. <br />
      <br />
      <br />
      I continued using Agile methodolgy into my promotion as Technical
      Operations Manager, and taught my team the value of Agile, resulting in
      three of my team members obtaining Agile certifications. We ran Scrum on
      an operational team, embracing the timing cadence of Scrum and the
      discpiline of Backlog Grooming. <br />
      <br />
      In my promotion to Senior Data Services Manager, I brought Scrum and
      Kanban to the Professional Services data department and reduced our work
      in progress by 50% over 8 months on our largest workstream.
      <br />
      <br />
    </p>
    <div className="Center">
      <img
        className="MainContent__Header__headerImage"
        src={ScrumProcess}
        alt="Scrum Process via Neon Rain"
      />
    </div>
    <div className="Center">
      <a
        style={{ color: "blue" }}
        href="https://www.neonrain.com/agile-scrum-web-development/"
        target="_blank"
        rel="noopener noreferrer"
      >
        Scrum Process via Neon Rain
      </a>
    </div>
  </div>
);

export default AgileAndTeams;
