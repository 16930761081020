import React from "react";
import "./BusinessCard.css";
import Headshot2 from "../../img/Jordan Headshot 2021.png";

const BusinessCard = (props) => (
  <div className="BusinessCardContainer">
    <div className="BusinessCard">
      <div className="BusinessCard__HeadshotContainer">
        <img
          className="BusinessCard__Headshot"
          src={Headshot2}
          alt="Jordan Buffaloe, shot by Josh Monesson Photography"
        />
      </div>
      <div className="BusinessCard__RightUpperCorner"></div>
      <div className="BusinessCard__UpperCenter">904-525-9229</div>
      <div className="BusinessCard__MidCenter">
        <p>Jordan Buffaloe</p>
      </div>
      <div className="BusinessCard__Bottom">
        <p>
          <a
            href="mailto:jordanbuffaloe@gmail.com?Subject=Website Introduction"
            target="_blank"
            rel="noopener noreferrer"
          >
            jordanbuffaloe@gmail.com
          </a>
        </p>
        <a
          href="https://www.linkedin.com/in/jordanbuffaloe/"
          target="_blank"
          rel="noopener noreferrer"
        >
          LinkedIn
        </a>
        <a
          href="https://github.com/jordanbuffaloe"
          target="_blank"
          rel="noopener noreferrer"
        >
          github
        </a>

        <a
          href="https://www.jordanbuffaloe.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Portfolio Website
        </a>
      </div>
    </div>
  </div>
);
export default BusinessCard;
