import React from "react";
import "./MainContentHeader.css";

const MainContentHeader = (props) => {
  const { altText, headerContent, headerImage, imageClass } = props;
  const date = Date.now();
  return (
    <div className="MainContent__Header">
      <div className="MainContent__Header__imgContainer">
        <img
          key={date}
          className={imageClass}
          alt={altText}
          src={headerImage}
        />
      </div>
      <div>
        <p>{headerContent}</p>
      </div>
    </div>
  );
};

export default MainContentHeader;
