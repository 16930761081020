import React from "react";

const DataServicesDashboard = () => (
  <div>
    <p>
      <span className="Bold">Contribution: </span>I was the Creator, Product
      Manager, Designer and Full-Stack Developer for this SQL / Express / React
      / Node (SERN) application called the Data Services Dashboard (DSD). This
      uses direct SQL queries against 2 production databases, as well as the
      API's of JIRA and Salesforce.
    </p>

    <p className="Bold">Business Accomplishments: </p>
    <ul style={{ padding: "0px 30px 25px" }}>
      <li>Generated time savings of 5+ weekly hours across 237 end users.</li>
      <li>
        Engineered API endpoints connecting Salesforce, JIRA and two SQL
        databases and allowing Read, Edit and Create operations. Data
        aggregation, consolidation and control to maximize operational
        efficiency,
      </li>
      <li>
        Transferred data exports onto client-facing spreadsheets by leveraging
        exceljs library. Saving 2 effort hours per spreadsheet.
      </li>
    </ul>
    <p>
      <span className="Bold">Modules: </span>This application is made of 11
      modules that serve internal business users across Operational departments.
    </p>

    <p>
      <span className="Bold">Background: </span>
      <span>
        The use cases began with my own teams, who used several systems to
        obtain common questions about work status. By consolidating the systems
        into one location, we could move more rapidly. We extended from READ
        only operations, to basic ticket creation, allowing for the import of
        data into one system into the work tracking of another. I began
        receiving requests from other departments to add functionality and
        automate various tasks, including custom reports combining data from
        multiple sources.
      </span>
    </p>
  </div>
);

export default DataServicesDashboard;
