import React from "react";
import "./SideBar.css";
import Content from "../../Content";

const SideBar = (props) => {
  const { setActiveContentID, setResetContent, activeContentID } = props;
  const setActiveContentAndResetMainContent = (id) => {
    setResetContent(true);
    setTimeout(() => setActiveContentID(id), 50);
    setTimeout(() => setResetContent(false), 100);
  };

  return (
    <div className="SideBar">
      <ul>
        {Content.map((item, i) => (
          <li
            className={`SideBar__item
              ${activeContentID === item.id ? "SideBar__item-active" : ""}
              ${item.id === 2 ? "Separator" : ""}`}
            onClick={() => setActiveContentAndResetMainContent(item.id)}
            key={item.id}
          >
            {item.title}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default SideBar;
