import React from "react";
import "./Badges.css";
import csmBadge from "../../img/seal-csm.png";
import safeBadge from "../../img/SAFe5 Badge.png";
import pmpBadge from "../../img/PMP Badge.png";

const Badges = (props) => {
  const { resetContent } = props;

  return (
    <div className="Badges">
      {/* <img src={csmBadge} alt="Scrum Alliance CSM Badge" /> */}
      <img src={safeBadge} alt="SAFe 5 Badge" />
      {/* <img src={pmpBadge} alt="Project Management Professional Badge" /> */}
    </div>
  );
};

export default Badges;
