import React from "react";

import Luigi from "../img/Luigi.jpeg";
import Aris from "../img/Aris.jpeg";
import Lyn from "../img/Lyn.png";
import Chris from "../img/Chris.jpeg";
import Jessica from "../img/Jessica.jpeg";
import Ed from "../img/hires/ed.jpeg";
import Kevin from "../img/hires/Kevin.jpeg";

const Recommendations = [
  {
    id: 0,
    recommender: "Luigi Patruno",
    recommenderLink: "http://www.mlinproduction.com/",
    recommenderTitle: (
      <span className="Bold">
        Founder,{" "}
        <a
          className="Bold"
          href="http://www.MLinProduction.com"
          rel="noopener noreferrer"
          target="_blank"
        >
          MLinProduction.com
        </a>{" "}
        and Director, Data Science at 2U
      </span>
    ),
    recommenderRelationship: "Former project team member, Namely",
    recommenderImage: Luigi,
    recommendationText:
      "Jordan is a thoughtful, caring, empathic people manager with a killer ability to execute on a business plan. He has a great ability to identify the actual business problem in a situation and filter out the noise which leads him to GETTING THINGS DONE. He was pivotal in my learning about project management and understanding the point of tools like JIRA and Confluence, which I admit was no small feat when I was a know-at-all junior engineer : D . If you need a hustler on your team, someone who doesn't care what it takes to get the job done while simultaneously ensuring the needs of each team member are met, look no further than J Buff.",
  },
  {
    id: 1,
    recommender: "Aris Mantopoulous",
    recommenderLink: "https://www.linkedin.com/in/aris-mantopoulos/",
    recommenderTitle: "Senior Strategy Consultant, Upskill Analytics",
    recommenderRelationship: "Former manager, Trilogy Education",
    recommenderImage: Aris,
    recommendationText:
      "Jordan is a world-class teacher! Students love him! He is exceptionally motivated, passionate, and truly committed to students' learning. He is incredibly clear in presenting complex topics, and confident in front of 60+ person classes. He exceeds expectations as a teacher by regularly spending countless hours outside of class time, including weekends, to hold office hours with his students. Jordan is responsive, dependable, adaptable, and loyal, especially when I needed him on several occasions to fill the role of emergency substitute instructor with only a few hours notice. Always humble and gracious; an absolute joy to work with!",
  },
  {
    id: 2,
    recommender: "Lyn Pickens",
    recommenderLink: "https://www.linkedin.com/in/lyn-pickens-5000099/",
    recommenderTitle: "Former Executive Assistant, EverBank",
    recommenderRelationship: "Former coworker, EverBank",
    recommenderImage: Lyn,
    recommendationText:
      "Jordan is a dedicated and detail oriented project manager with the added skill of being a highly effective communicator at all levels of development.",
  },
  {
    id: 3,
    recommender: "Jessica Davis",
    recommenderLink: "https://www.linkedin.com/in/jessica-davis-281a5923/",
    recommenderTitle: "Enrollment Operations Manager at Namely",
    recommenderRelationship: "Current coworker",
    recommenderImage: Jessica,
    recommendationText: `Jordan combines his wealth of technical acuity and innate desire to help in order to drive product and process improvements. Jordan has literally built tools from scratch in his “spare time” that have made meaningful impacts on the day to day lives of myself, my team, and our clients. I’ve worked with Jordan over the years on several projects & I can honestly say that without his partnership my team would not have seen the operational efficiencies we so desperately needed. Jordan was willing to really dive in and understand the problem, make data driven decisions, & used his skill sets to really own the solution.`,
  },
  {
    id: 4,
    recommender: "Chris Collier",
    recommenderLink: "https://www.linkedin.com/in/chris-collier-675607133/",
    recommenderTitle: "Data Services Consultant",
    recommenderRelationship: "Former employee",
    recommenderImage: Chris,
    recommendationText: `I had the amazing fortune of starting my career working for Jordan Buffaloe, and I owe him a monumental thanks for everything he’s done for me.
      He taught me by example what it looks like for managers to truly champion their employees, and by extension I’ve learned what it means to be my own advocate. From my first day at Namely, Jordan has been an incredible mentor, giving me the confidence and the tools to get more technical than I thought I was capable, and I will be forever grateful for the foundation he helped me build.
      He truly cares about each and every one of his employees and would carve out time after work to lead SQL and Javascript lessons for the team to help build their skills. When he saw operational inefficiencies, he single-handedly built and maintained an internal web-app complete with automation, specialized reports, and aggregation of system information that saved countless hours of toil on our team and across the company.
      I could write at length about how lucky I’ve been to work under Jordan, so I’ll stop myself here and to all you SoCal start-ups: hire him before someone else does! He’ll make your employees happier, he’ll make your company more operationally efficient, and he’ll add value to your business.
      Thank you Jordan!`,
  },
  {
    id: 5,
    recommender: "Ed Burnett",
    recommenderLink: "https://www.linkedin.com/in/edjburnett/",
    recommenderTitle: "VP of Engineering",
    recommenderRelationship: "Former manager",
    recommenderImage: Ed,
    recommendationText: `I worked with Jordan very closely for over 3 years at Namely, covering a huge variety of Engineering and Operations challenges across technical and non technical teams, through some of the hardest problems I've encountered. There are very few people I have worked with that are as valuable as Jordan. Highly recommended.`,
  },
  {
    id: 6,
    recommender: "Kevin Thompson",
    recommenderLink: "https://www.linkedin.com/in/kevindthompsonvpps/",
    recommenderTitle: "VP of Professional Services",
    recommenderRelationship: "Former manager",
    recommenderImage: Kevin,
    recommendationText: `I worked with Jordan at Namely. Jordan has a great engineering mindset and effectively breaks down business issues to their key components to provide targeted business solutions. Jordan is one of those unique technology leaders who can understand the latest technologies and development frameworks, then quickly and effectively communicate those concepts in a meaningful way to C-level executives. He develops highly motivated teams that accomplish lofty goals while looking to continuously simplify and improve go forward processes making future endeavors easier and more efficient. Likewise, Jordan can jump in and code high function tools and applications that add immediate value and effort reduction. I would highly recommend Jordan to any company that is in need of a technical manager who can deliver exceptional results through a team or as an individual contributor with excellent skills translating business needs into cost-effective technology solutions.`,
  },
];

export default Recommendations;
