import React from "react";
import "./NavBar.css";
import { NavLink } from "react-router-dom";

const NavBar = (props) => (
  <div className="NavBarContainer">
    <div className="NavBar__header">
      <p>JORDAN BUFFALOE</p>
      {/* <div className="NavBar__titles">PM, MBA, PMP, CSM, SAFe 5 Agilist</div> */}
      <div className="NavBar__location">VENICE, CA</div>
    </div>
    <nav className="NavBar">
      <NavLink
        activeClassName="NavBar__item-active"
        className="NavBar__item"
        exact
        to="/"
      >
        Portfolio
      </NavLink>
      {/* <NavLink
        activeClassName="NavBar__item-active"
        className="NavBar__item"
        to="/vr"
      >
        VR
      </NavLink> */}
      <NavLink
        activeClassName="NavBar__item-active"
        className="NavBar__item"
        to="/resume"
      >
        Resume
      </NavLink>
      <NavLink
        activeClassName="NavBar__item-active"
        className="NavBar__item"
        to="/contact"
      >
        Contact
      </NavLink>
    </nav>
  </div>
);

export default NavBar;
