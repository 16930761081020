import React from "react";

const Portfolio = () => (
  <div className="PortfolioContent">
    {`This Boostrap-free site was built using React and was my first exploration
    into light-weight React Hooks, and mobile-responsiveness using only CSS Grid.I am also used to reaching for animation libraries, but basic animations were easily achieved using CSS alone.`}
  </div>
);

export default Portfolio;
